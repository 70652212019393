



export const mockAccountStatsData = {
    analysis_hours_current: 1500,
    analysis_hours_max: 10000,
    storage_current: 2000,
    storage_max: 10000
}

// Used In: DemoRectangle.tsx
export const tempCurrentUserDemoFeaturesList = [
    "View the platform to familiarize yourself with its functionality",
    "Work with a preloaded, analyzed RNA-Seq data set",
    "The ability to make changes to graphs and see the effects in real time"
]

export const mockFormattedAnalyses = [
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "b8da6d66-3087-407c-ae70-9bd712134115",
        time_started: "2022-10-14T01:11:36.578Z",
        status: "failed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "fa81d4ea-3389-47a7-b11d-17b49245dfca",
        time_started: "2023-03-24T23:39:50.772Z",
        status: "completed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "fa81d4ea-3389-47a7-b11d-17b49245dfca",
        time_started: "2022-10-14T18:57:11.870Z",
        status: "completed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "924f5727-67f6-41b2-8475-35198e0abf9c",
        time_started: "2022-10-14T20:56:17.268Z",
        status: "completed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "1ed865b8-19fc-4901-9302-75cc152ccb6f",
        time_started: "2022-11-19T03:39:15.961Z",
        status: "completed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "f2a0cf89-df8d-4369-b6bb-7b2a94a232cb",
        time_started: "2022-11-21T20:59:06.362Z",
        status: "completed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "75de3094-9b89-4c02-b5b8-84b0db7e8056",
        time_started: "2022-10-14T22:44:51.447Z",
        status: "failed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "f28a84fa-0c0a-49fb-bc4e-7395a3c54915",
        time_started: "2022-11-21T21:37:43.399Z",
        status: "failed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "402641e8-1b8e-43d2-8cca-6b64fbb441dc",
        time_started: "2022-11-22T00:12:23.625Z",
        status: "completed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "83737101-8e60-4767-8fb1-9623c6c6ed46",
        time_started: "2022-11-21T21:39:20.722Z",
        status: "failed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "7f644104-88c1-4fae-af35-8d07d7fb9cb6",
        time_started: "2022-11-19T02:58:30.006Z",
        status: "completed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "9d10deba-6fe1-4317-9de0-6e4f45dd11db",
        time_started: "2022-10-14T21:43:04.789Z",
        status: "running"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "8419841b-39e0-4411-8a47-808b5768ec1f",
        time_started: "2022-11-21T21:12:22.702Z",
        status: "completed"
    },
    {
        experiment_id: "22c261b9-f4d6-4900-8838-c5f416ea0ac5",
        experiment_name: "DCM_dataset",
        analysis_id: "ca43eff7-eab7-4dfb-b899-e3d1dcc8ae3d",
        time_started: "2022-11-22T00:11:22.601Z",
        status: "completed"
    }
]


export const demoExperiment =  {
    id: "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9",
    created_time: "2023-10-05T19:21:55.555Z",
    created_by: "440f10bf-0bc4-4492-af23-2453d750a12a",
    ex_id: "4e16d2bb-573e-44c8-b0e8-d75cc2c2b29e",
    ex_time: "2023-10-05T19:21:55.555Z",
    ex_user: "440f10bf-0bc4-4492-af23-2453d750a12a",
    ex: {
        key: "value1"
    },
    name: "Transcriptomic Changes in Dilated Cardiomyopathy",
    description:
    "Transcriptomic analysis of human heart failure reveals dysregulated gene profiles in dilated cardiomyopathy./n The data in this demo dataset is obtained from the work of Sweet ME, et al 2018 (data accessible at NCBI GEO database, accession GSE116250).",
    organism: "Homo_sapiens",
    sequencing_type: "RNA-Seq",
    analyzed_molecule: "mRNA",
    rna_selection_method: "polyA Selection",
    sequencing_adapter: "Illumina TruSeq Adapters",
    sequencing_platform: "Illumina",
    platform_model: "Illumina HiSeq 1500",
    sequencing_read_type: "Single-end",
    sequencing_sense: "Unstranded",
    archived: false,
    pending: false,
    group_options: {},
    experiment_num: "62",
    analysis_count: "1",
}

export const demoExperiments = [
    {
        id: "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9",
        created_time: "2023-10-05T19:21:55.555Z",
        created_by: "440f10bf-0bc4-4492-af23-2453d750a12a",
        ex_id: "4e16d2bb-573e-44c8-b0e8-d75cc2c2b29e",
        ex_time: "2023-10-05T19:21:55.555Z",
        ex_user: "440f10bf-0bc4-4492-af23-2453d750a12a",
        ex: {
            key: "value1"
        },
        name: "Transcriptomic Changes in Dilated Cardiomyopathy",
        description:
        "Transcriptomic analysis of human heart failure reveals dysregulated gene profiles in dilated cardiomyopathy.\n The data in this demo dataset is obtained from the work of Sweet ME, et al 2018 (data accessible at NCBI GEO database, accession GSE116250)." ,
        organism: "Homo_sapiens",
        sequencing_type: "RNA-Seq",
        analyzed_molecule: "mRNA",
        rna_selection_method: "polyA Selection",
        sequencing_adapter: "Illumina TruSeq Adapters",
        sequencing_platform: "Illumina",
        platform_model: "Illumina HiSeq 1500",
        sequencing_read_type: "Single-end",
        sequencing_sense: "Unstranded",
        archived: false,
        pending: false,
        group_options: {},
        experiment_num: "62",
        analysis_count: "1",
        sample_count: "51"
    }
]


export const demoSamples = [
    {
        "id": "08a536d5-79dd-46e6-9df1-03b26b4adb9a",
        "created_time": "2024-02-09T15:23:56.122Z",
        "created_by": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_id": "f3feb0ac-082b-4f60-b89f-3c7f9ae006d6",
        "ex_user": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_time": "2024-02-09T15:23:56.122Z",
        "ex": {
            "analyzed_molecule": "mRNA + IncRNA",
            "experiment_id": "2943c6c5-2c86-4949-acf8-4c4b5e5af589",
            "name": "SampleName4",
            "organism": "Homo sapiens",
            "other_categories": {
                "Age": 45,
                "Disease": "dilated",
                "Sex": "male"
            },
            "sequencing_instrument_model": "Illumina MiniSeqDx",
            "sequencing_instrument_platform": "Illumina"
        },
        "name": "SampleName4",
        "description": null,
        "basename": null,
        "sample_description": null,
        "sample_number": null,
        "sample_name": null,
        "sex": null,
        "source_tissue_name": null,
        "source_organ_name": null,
        "organism": "Homo sapiens",
        "condition": null,
        "treatment_protocol": null,
        "treatment_type": null,
        "growth_protocol": null,
        "analyzed_molecule": "mRNA + IncRNA",
        "extract_protocol": null,
        "library_strategy": null,
        "library_construction_protocol": null,
        "sequencing_instrument_platform": "Illumina",
        "sequencing_instrument_model": "Illumina MiniSeqDx",
        "adapter_for_trimming": null,
        "other_categories": {
            "Age": 45,
            "Disease": "dilated",
            "Sex": "male"
        },
        "number_of_files": null,
        "status": null,
        "archived": null,
        "flag": null,
        "experiment_id": "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9",
        "sample_num": "3113",
        "files": []
    },
    {
        "id": "3b670348-5f72-4fb4-b75c-f0c04ccb3304",
        "created_time": "2024-02-09T15:23:56.124Z",
        "created_by": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_id": "cab6bbe5-6dd2-4c07-b23c-1954d8a34b03",
        "ex_user": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_time": "2024-02-09T15:23:56.124Z",
        "ex": {
            "analyzed_molecule": "mRNA + IncRNA",
            "experiment_id": "2943c6c5-2c86-4949-acf8-4c4b5e5af589",
            "name": "SampleName5",
            "organism": "Homo sapiens",
            "other_categories": {
                "Age": 46,
                "Disease": "control",
                "Sex": "female"
            },
            "sequencing_instrument_model": "Illumina MiniSeqDx",
            "sequencing_instrument_platform": "Illumina"
        },
        "name": "SampleName5",
        "description": null,
        "basename": null,
        "sample_description": null,
        "sample_number": null,
        "sample_name": null,
        "sex": null,
        "source_tissue_name": null,
        "source_organ_name": null,
        "organism": "Homo sapiens",
        "condition": null,
        "treatment_protocol": null,
        "treatment_type": null,
        "growth_protocol": null,
        "analyzed_molecule": "mRNA + IncRNA",
        "extract_protocol": null,
        "library_strategy": null,
        "library_construction_protocol": null,
        "sequencing_instrument_platform": "Illumina",
        "sequencing_instrument_model": "Illumina MiniSeqDx",
        "adapter_for_trimming": null,
        "other_categories": {
            "Age": 46,
            "Disease": "control",
            "Sex": "female"
        },
        "number_of_files": null,
        "status": null,
        "archived": null,
        "flag": null,
        "experiment_id": "2943c6c5-2c86-4949-acf8-4c4b5e5af589",
        "sample_num": "3115",
        "files": []
    },
    {
        "id": "c11678e0-6702-49fb-80c9-133441e3003d",
        "created_time": "2024-02-09T15:23:56.126Z",
        "created_by": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_id": "93f0f502-4b4a-46ae-97ed-83d29268e542",
        "ex_user": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_time": "2024-02-09T15:23:56.126Z",
        "ex": {
            "analyzed_molecule": "mRNA + IncRNA",
            "experiment_id": "2943c6c5-2c86-4949-acf8-4c4b5e5af589",
            "name": "SampleName3",
            "organism": "Homo sapiens",
            "other_categories": {
                "Age": 44,
                "Disease": "control",
                "Sex": "female"
            },
            "sequencing_instrument_model": "Illumina MiniSeqDx",
            "sequencing_instrument_platform": "Illumina"
        },
        "name": "SampleName3",
        "description": null,
        "basename": null,
        "sample_description": null,
        "sample_number": null,
        "sample_name": null,
        "sex": null,
        "source_tissue_name": null,
        "source_organ_name": null,
        "organism": "Homo sapiens",
        "condition": null,
        "treatment_protocol": null,
        "treatment_type": null,
        "growth_protocol": null,
        "analyzed_molecule": "mRNA + IncRNA",
        "extract_protocol": null,
        "library_strategy": null,
        "library_construction_protocol": null,
        "sequencing_instrument_platform": "Illumina",
        "sequencing_instrument_model": "Illumina MiniSeqDx",
        "adapter_for_trimming": null,
        "other_categories": {
            "Age": 44,
            "Disease": "control",
            "Sex": "female"
        },
        "number_of_files": null,
        "status": null,
        "archived": null,
        "flag": null,
        "experiment_id": "2943c6c5-2c86-4949-acf8-4c4b5e5af589",
        "sample_num": "3116",
        "files": []
    },
    {
        "id": "526d331e-ef81-46c2-b315-27d20c3530d5",
        "created_time": "2024-02-09T15:23:56.123Z",
        "created_by": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_id": "ff0290f4-9dbf-433c-a28c-008040d5967e",
        "ex_user": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_time": "2024-02-09T15:23:56.123Z",
        "ex": {
            "analyzed_molecule": "mRNA + IncRNA",
            "experiment_id": "2943c6c5-2c86-4949-acf8-4c4b5e5af589",
            "name": "SampleName2",
            "organism": "Homo sapiens",
            "other_categories": {
                "Age": 43,
                "Disease": "dilated",
                "Sex": "male"
            },
            "sequencing_instrument_model": "Illumina MiniSeqDx",
            "sequencing_instrument_platform": "Illumina"
        },
        "name": "SampleName2",
        "description": null,
        "basename": null,
        "sample_description": null,
        "sample_number": null,
        "sample_name": null,
        "sex": null,
        "source_tissue_name": null,
        "source_organ_name": null,
        "organism": "Homo sapiens",
        "condition": null,
        "treatment_protocol": null,
        "treatment_type": null,
        "growth_protocol": null,
        "analyzed_molecule": "mRNA + IncRNA",
        "extract_protocol": null,
        "library_strategy": null,
        "library_construction_protocol": null,
        "sequencing_instrument_platform": "Illumina",
        "sequencing_instrument_model": "Illumina MiniSeqDx",
        "adapter_for_trimming": null,
        "other_categories": {
            "Age": 43,
            "Disease": "dilated",
            "Sex": "male"
        },
        "number_of_files": null,
        "status": null,
        "archived": null,
        "flag": null,
        "experiment_id": "2943c6c5-2c86-4949-acf8-4c4b5e5af589",
        "sample_num": "3117",
        "files": []
    },
    {
        "id": "7c4ae0e7-0aa3-4572-9b4a-715a92acadc0",
        "created_time": "2024-02-09T15:23:56.122Z",
        "created_by": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_id": "6bb22fcc-4cb9-42e3-91b7-061b28e60843",
        "ex_user": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_time": "2024-02-09T15:23:56.122Z",
        "ex": {
            "analyzed_molecule": "mRNA + IncRNA",
            "experiment_id": "2943c6c5-2c86-4949-acf8-4c4b5e5af589",
            "name": "SampleName1",
            "organism": "Homo sapiens",
            "other_categories": {
                "Age": 42,
                "Disease": "control",
                "Sex": "female"
            },
            "sequencing_instrument_model": "Illumina MiniSeqDx",
            "sequencing_instrument_platform": "Illumina"
        },
        "name": "SampleName1",
        "description": null,
        "basename": null,
        "sample_description": null,
        "sample_number": null,
        "sample_name": null,
        "sex": null,
        "source_tissue_name": null,
        "source_organ_name": null,
        "organism": "Homo sapiens",
        "condition": null,
        "treatment_protocol": null,
        "treatment_type": null,
        "growth_protocol": null,
        "analyzed_molecule": "mRNA + IncRNA",
        "extract_protocol": null,
        "library_strategy": null,
        "library_construction_protocol": null,
        "sequencing_instrument_platform": "Illumina",
        "sequencing_instrument_model": "Illumina MiniSeqDx",
        "adapter_for_trimming": null,
        "other_categories": {
            "Age": 42,
            "Disease": "control",
            "Sex": "female"
        },
        "number_of_files": null,
        "status": null,
        "archived": null,
        "flag": null,
        "experiment_id": "2943c6c5-2c86-4949-acf8-4c4b5e5af589",
        "sample_num": "3112",
        "files": []
    },
    {
        "id": "d60223c1-bf82-4ddb-b202-9bad20e78a6d",
        "created_time": "2024-02-09T15:23:56.125Z",
        "created_by": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_id": "2169165c-126b-4cb5-947a-4e8f0558e76f",
        "ex_user": "0a4c347b-8e6a-40d1-940f-a76f32940dae",
        "ex_time": "2024-02-09T15:23:56.125Z",
        "ex": {
            "analyzed_molecule": "mRNA + IncRNA",
            "experiment_id": "2943c6c5-2c86-4949-acf8-4c4b5e5af589",
            "name": "SampleName6",
            "organism": "Homo sapiens",
            "other_categories": {
                "Age": 47,
                "Disease": "dilated",
                "Sex": "male"
            },
            "sequencing_instrument_model": "Illumina MiniSeqDx",
            "sequencing_instrument_platform": "Illumina"
        },
        "name": "SampleName6",
        "description": null,
        "basename": null,
        "sample_description": null,
        "sample_number": null,
        "sample_name": null,
        "sex": null,
        "source_tissue_name": null,
        "source_organ_name": null,
        "organism": "Homo sapiens",
        "condition": null,
        "treatment_protocol": null,
        "treatment_type": null,
        "growth_protocol": null,
        "analyzed_molecule": "mRNA + IncRNA",
        "extract_protocol": null,
        "library_strategy": null,
        "library_construction_protocol": null,
        "sequencing_instrument_platform": "Illumina",
        "sequencing_instrument_model": "Illumina MiniSeqDx",
        "adapter_for_trimming": null,
        "other_categories": {
            "Age": 47,
            "Disease": "dilated",
            "Sex": "male"
        },
        "number_of_files": null,
        "status": null,
        "archived": null,
        "flag": null,
        "experiment_id": "2943c6c5-2c86-4949-acf8-4c4b5e5af589",
        "sample_num": "3114",
        "files": []
    }
]


export const createSample = async (sample, fileObjects) => {

    // const { getAccessTokenSilently } = useAuth0()
    // const { apiHost } = getAppConfig()
    // const { user } = useTrovoConfig()

    const { SampleName: sampleName, Filename: filename, ...other_categories } = sample
    const fileObjectsToInsert = fileObjects.map((obj) => {
        const filename = obj.name
        const filenameSplit = filename.split("_")
        const laneNumber = filenameSplit.length >= 5 ? filenameSplit[filenameSplit.length - 3][3] : "1"
        const readNumber =
            filenameSplit.length >= 5
                ? filenameSplit[filenameSplit.length - 2][1]
                : filenameSplit.length === 2
                ? filenameSplit[1][0]
                : "1"
        return {
            // name: filename,
            // original_size: obj.size,
            // strandedness_seq_sense: formData.sequencing_details.sequencing_sense,
            // strandedness_seq_end:
            //     formData.sequencing_details.sequencing_read_type &&
            //     formData.sequencing_details.sequencing_read_type.toLowerCase(),
            // interleaved: false,
            // status: "unknown",
            // lane_number: laneNumber,
            // read_number: readNumber
        }
    })

//     const sampleRecord = {
//         name: sampleName,
//         organism: formData.experiment_details.organism,
//         analyzed_molecule: formData.sequencing_details.analyzed_molecule,
//         sequencing_instrument_platform: formData.sequencing_details.sequencing_platform,
//         sequencing_instrument_model: formData.sequencing_details.platform_model,
//         experiment_id: id,
//         other_categories: other_categories,
//         files: fileObjectsToInsert
//     }
//     return fetch(`${apiHost}/experiment/${id}/sample/create`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${await getAccessTokenSilently()}`
//         },
//         body: JSON.stringify(sampleRecord)
//     })
 }