import React from "react";
import Box from "@mui/material/Box";

import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
type HowTooTipProps = TooltipProps & {
  condition: boolean;
};
export function HowToTip({ title, condition, children }: HowTooTipProps) {
  return (
    <Tooltip
      sx={{
        ...(condition && {
          animation: "pulse 2s infinite",
          "@keyframes pulse": {
            "0%": {
              transform: "scale(0.95)",
              boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)",
            },

            "70%": {
              transform: "scale(1)",
              boxShadow: "0 0 0 4px rgba(0, 0, 0, 0)",
            },

            "100%": {
              transform: "scale(0.95)",
              boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
            },
          },
        }),
      }}
      slotProps={{
        popper: { style: { zIndex: 1200 } },
      }}
      arrow
      placement={"right"}
      title={title}
      open={condition}
    >
      <Box
        sx={{
          padding: "unset",
          ...(condition && {
            animation: "pulse 2s infinite",
            "@keyframes pulse": {
              "0%": {
                transform: "scale(0.95)",
              },

              "70%": {
                transform: "scale(1)",
              },

              "100%": {
                transform: "scale(0.95)",
              },
            },
          }),
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
}
