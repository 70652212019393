import React from "react"
import { Typography, Button } from "@mui/material"
import { styled } from "@mui/material/styles";
import { CopyText } from "../../copyText";

export const ContainerStyled = styled("div")({
    padding: "30px",
    
})

export const TitleStyled = styled(Typography)({
    fontSize: "24px",
    marginBottom: "30px"
})

export const TextStyled = styled(Typography)({
    fontSize: "14px",
    marginBottom: "20px"
})

export const ButtonStyled = styled(Button)({
    width: "100%",
    marginTop: "40px",
    backgroundColor: "black"
})

const TEXT_TEMPLATE = `
                Hello <b>[Sequencing Company]</b>, <br/><br/>

                We are following up with some questions about the sequencing you performed for us for <b>[Name of <br/>
                Experiment, Laboratory Contact and/or Order Number]</b>. Can you answer the questions below?<br/><br/>

                1.Which RNA molecule was analyzed? (mRNA, mRNA=IncRNA, smallRNA)<br/>
                2.Which RNA selection method was used to enrich the sample(s)? (polyA selection, rRNA depletion, size fractionation)<br/>
                3.What adapters were used for sequencing my samples?<br/>
                4.What was the sequencing platform and model used for sequencing? (Illumina NOvaSeq, HiSeq, etc)<br/>
                5.Was the sequencing read type paired end or single end?<br/>
                6.Which strand was selected during library preparation (unstranded, forward, reverse)<br/>

                Thank you,<br/>
                <b>[Customer Name]</b>
`;

interface RecallEmailModalProps {
    onClose?: () => void
}

export const RecallEmail: React.FC<RecallEmailModalProps> = ({ onClose }) => {
    return (
        <ContainerStyled>
            <TitleStyled>
                Before you proceed...
            </TitleStyled>
            <TextStyled>
                There is a set of required information that we need in order to process your data. This information comes from the organization that provided you with your data files. You need to enter this information in order to proceed with your experiment setup.
            </TextStyled>
            <TextStyled>
                If you already have this information, you can proceed with your experiment creation. If you don’t have this information, we’ve drafted the following email template for you to use to request this information from the organization. Once you have this information, you can continue.
            </TextStyled>
            <CopyText text={TEXT_TEMPLATE} />

            <ButtonStyled onClick={onClose} variant="contained">
                Got it!
            </ButtonStyled>
        </ContainerStyled>
    );
}
