import { Box, Typography, Modal, TextField, Button, Divider } from "@mui/material"
import React, { useState } from "react"
import CostSummary from "../../CostSummary"
import { CardComponent, CardNumber, CardExpiry, CardCVV } from "@chargebee/chargebee-js-react-wrapper"
import LoadingButton from "@mui/lab/LoadingButton"
import { useCCForm } from "../../../hooks/useCCForm"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert"
import CostSummaryInitial from "../../CostSummaryInitial"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "930px",
    display: "flex",
    flexDirection: "column",
    bgcolor: "#FFF",
    borderRadius: "12px",
    boxShadow: 24
}
interface ToPaidModalProps {
    open: boolean
    setClose: () => void
    changePlan: (token?: string, code?: string) => Promise<string>
    planInfo: Plan
}

const inputStyles = {
    base: {
        color: "#49454F",
        fontSize: "16px",
        lineHeight: "40px"
    }
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const ToPaidModal: React.FC<ToPaidModalProps> = ({ open, setClose, changePlan, planInfo }) => {
    const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: AlertColor }>({
        open: false,
        message: "",
        severity: "error"
    })
    const [code, setCode] = useState<string>("")
    const [codeValid, setCodeValid] = useState<boolean>(false)
    const [discountPercentage, setDiscountPercentage] = useState<number>(0)
    const needsCC = !codeValid || discountPercentage !== 100
    const {
        handleFormChange,
        isComplete,
        isLoading,
        cardRef,
        formErrors,
        handleFormSubmit,
        name: { name, setName }
    } = useCCForm(changePlan, setSnackbar)

    return (
        <>
            <Modal open={open}>
                <Box sx={style}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        padding="36px"
                        gap="39px"
                        height="528px"
                        alignItems="flex-start"
                        justifyContent="space-between">
                        {!needsCC ? (
                            <Typography>No card payment needed.</Typography>
                        ) : (
                            <Box>
                                <Typography variant="headline" size="small">
                                    Enter your secure payment details
                                </Typography>
                                <CardComponent
                                    ref={cardRef}
                                    className="pay-outer"
                                    icon={true}
                                    onChange={handleFormChange}>
                                    <Box>
                                        {formErrors.error && (
                                            <Typography color="error" variant="body1">
                                                {formErrors.error}
                                            </Typography>
                                        )}
                                    </Box>
                                    <TextField
                                        data-test-id="name"
                                        variant="outlined"
                                        label="Name on Card"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "16px",
                                            letterSpacing: "0.5px",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#79747e"
                                            }
                                        }}
                                    />
                                    <Box display="flex" flexDirection="column">
                                        {formErrors.number && (
                                            <Typography color="error" variant="body1">
                                                {formErrors.number}
                                            </Typography>
                                        )}
                                        <div data-test-id="card-number-iframe">
                                            <CardNumber className="pay-input number" styles={inputStyles} />
                                        </div>
                                    </Box>
                                    <Box display="flex" flexDirection="row" gap="24px" alignItems="flex-end">
                                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            {formErrors.expiry && (
                                                <Typography color="error" variant="body1">
                                                    {formErrors.expiry}
                                                </Typography>
                                            )}
                                            <div data-test-id="expiry-iframe">
                                                <CardExpiry className="pay-input expiry" styles={inputStyles} />
                                            </div>
                                        </Box>
                                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            {formErrors.cvv && (
                                                <Typography color="error" variant="body1">
                                                    {formErrors.cvv}
                                                </Typography>
                                            )}
                                            <div data-test-id="cvv-iframe">
                                                <CardCVV className="pay-input cvv" styles={inputStyles} />
                                            </div>
                                        </Box>
                                    </Box>
                                </CardComponent>
                            </Box>
                        )}
                        <CostSummaryInitial
                            plan={planInfo}
                            code={code}
                            setCode={setCode}
                            discountPercentage={discountPercentage}
                            setDiscountPercentage={setDiscountPercentage}
                            codeValid={codeValid}
                            setCodeValid={setCodeValid}
                        />
                    </Box>
                    <Divider />
                    <Box padding="24px" display="flex" justifyContent="flex-end" gap="8px">
                        <Button
                            variant="text"
                            sx={{ width: "67px" }}
                            onClick={() => {
                                setCode("")
                                setCodeValid(false)
                                setDiscountPercentage(0)
                                setClose()
                            }}>
                            Cancel
                        </Button>
                        <LoadingButton
                            variant="contained"
                            disabled={!isComplete && needsCC}
                            loading={isLoading}
                            onClick={needsCC ? handleFormSubmit : () => changePlan(undefined, code)}
                            sx={{
                                width: "240px"
                            }}>
                            {needsCC ? "Submit Payment" : "Upgrade Now"}
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ open: false, message: "", severity: "error" })}>
                <Alert
                    onClose={() => setSnackbar({ open: false, message: "", severity: "error" })}
                    severity={snackbar.severity}
                    sx={{ width: "100%" }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default ToPaidModal
