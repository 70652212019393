import React from "react"
import Modal from "@mui/material/Modal"
import { styled } from "@mui/material/styles"

const PopupContentStyled = styled("div")({
    width: "865px",
    position: "absolute",
    top: "49%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    overflow: "auto",
    maxHeight: '96vh'
})

type ModalProps = {
    isOpen: boolean
    onClose?: (...args: any[]) => any
    children: React.ReactNode
}

export default function Popup({ isOpen, onClose, children, ...props }: ModalProps) {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
             BackdropProps={{ style:{ background:'#0E0E0F', opacity:'94%'} }}>
            <PopupContentStyled sx={{ bgcolor:'surface-container-lowest'  }} >{children}</PopupContentStyled>
        </Modal>
    )
}
