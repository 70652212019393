import { GeneralGraphProps } from "../../../types/GraphTypes";

export interface PCAProps extends GeneralGraphProps {
  geneList: string[];
  geneLists: string[];
  markSize: number;
  xPC: number;
  yPC: number;
  ellipseMeta?: string;
  ellipseDeviations?: number;
}

export const pcaConfig: PCAProps = {
  xTitle: "",
  title: "PCA Plot",
  xPC: 0,
  yPC: 1,
  xTitlePos: 0,
  yTitlePos: 0.9,
  yTitle: "",
  fontSizeTitle: 20,
  fontSizeX: 12,
  fontSizeY: 12,
  width: 800,
  height: 500,
  geneList: [],
  geneLists: [],
  showGrid: false,
  setDefaults: true,
  mLeft: 0,
  markSize: 5,
};
