import React from "react"
import { Button, IconButton, TextField, InputAdornment, Box, Typography } from "@mui/material"
import { Search, Add, ControlPointDuplicate, DeleteOutline, Rule, SettingsOutlined } from "@mui/icons-material"
import { useTrovoConfig } from "../utils/config"

interface ButtonRowProps {
    areSelectedSamples: boolean;
    onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    addClick?: (event: boolean) => void;
    onDelete?: () => void
    setDuplicationStarted: (event: boolean) => void
}

export const ButtonRow: React.FC<ButtonRowProps> = ({
    areSelectedSamples,
    onSearchChange,
    addClick,
    onDelete ,
    setDuplicationStarted
}) => {
    const { user } = useTrovoConfig()
    const onDuplicateHandle = () => setDuplicationStarted(true)

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "20px 0" }}>
            <div>
                <Button
                    onClick={() => (addClick ? addClick(true) : null)}
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    sx={{ borderRadius: "5px", marginRight: "5px" }}
                    disabled={user.cb_item_price_id?.includes("demo")}>
                    <Typography variant="label" size="large">
                        Add Sample
                    </Typography>
                </Button>
                {/* Filter Button Below is temporarily disabled */}
                {/* <IconButton color="primary" aria-label="filter" sx={{border: '1px solid', borderRadius:'5px', borderColor:'outline-variant',  mx:1}}>
                    <Rule />
                    <Typography variant='label' size='large' mx={1}> Filter </Typography>
                </IconButton> */}
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    onChange={onSearchChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </div>

            <div>
                <IconButton
                    color="primary"
                    aria-label="delete"
                    size="small"
                    sx={{ border: "1px solid", borderRadius: "5px", borderColor: "outline-variant", py: "4px", mx: 1 }}
                    disabled={user.cb_item_price_id?.includes("demo") || !areSelectedSamples}
                    onClick={onDelete}
                    data-testid={'delete-button'}
                >
                    <DeleteOutline />
                    <Typography variant="label" size="large" mx={1}>
                        Delete
                    </Typography>
                </IconButton>
                <IconButton
                    onClick={onDuplicateHandle}
                    color="primary"
                    aria-label="duplicate"
                    size='small' sx={{border: '1px solid', borderRadius:'5px', borderColor:'outline-variant', py:'4px'}}
                    disabled={!areSelectedSamples}
                >
                    <ControlPointDuplicate />
                    <Typography variant='label' size='large' mx={1} >
                        Duplicate
                    </Typography>
                </IconButton>
            </div>
        </Box>
    )
}
