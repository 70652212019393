import * as React from 'react';
import {
    PermIdentityOutlined,
    ArrowDropDownOutlined,
    ArrowDropUpOutlined
} from "@mui/icons-material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import { MouseEventHandler, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "./buttons/Button";
import ThemeToggle from "./ThemeToggle";
import { ColorModeContext } from "../ColorModeContext";

const ButtonStyled = styled(Button)({
    width: 67,
    height: '32px',
    backgroundColor: "white",
    margin: "0 10px",
    borderRadius: "30px",
    '&:hover': {
        backgroundColor: "white"
    }
});

const MenuStyled = styled(Menu)({
    padding: "0",
    '& .MuiList-root': {
        padding: "0",
    }
});

const MenuItemStyled = styled(MenuItem)({
    minWidth: "229px",
    padding: "10px 20px",
    '&:not(:last-child)': {
        borderBottom: '1px solid #ccc',
    }
});

const ThemeSwitcherStyled = styled(MenuItem)({
    minWidth: "229px",
    padding: "0 20px 0 10px",
    '&:not(:last-child)': {
        borderBottom: '1px solid #ccc',
    }
});

type ProfileMenuProps = {
    logout: MouseEventHandler
}

export function ProfileMenu({ logout }: ProfileMenuProps) {
    const colorMode = useContext(ColorModeContext)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const onThemeSwitch = () => {
        colorMode.toggleColorMode()
    }

    return (
        <>
            <ButtonStyled
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            ><svg width={0} height={0}> <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1} >
                <stop offset={0} stopColor="#7C57F4" />
                <stop offset={4} stopColor="#F7218C" /> 
            </linearGradient> 
                </svg>
                <PermIdentityOutlined sx={{ fill: "url(#linearColors)" }} />
                {open ?
                    <ArrowDropUpOutlined sx={{ fill: "url(#linearColors)" }} />
                    : <ArrowDropDownOutlined sx={{ fill: "url(#linearColors)" }} />
                }
            </ButtonStyled>
            <MenuStyled
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
                {/*<ThemeSwitcherStyled onClick={onThemeSwitch}>*/}
                {/*    <ThemeToggle /> Switch Theme*/}
                {/*</ThemeSwitcherStyled>*/}
                {/*<MenuItemStyled onClick={handleClose}>*/}
                {/*    <Link to='/account/plan-info'>Account Profile</Link>*/}
                {/*</MenuItemStyled>*/}
                <MenuItemStyled onClick={handleClose}>
                    <Link to='/account/plan-info'>Plan & Billing</Link>
                </MenuItemStyled>
                <MenuItemStyled onClick={logout}>
                    LOG OUT
                </MenuItemStyled>
            </MenuStyled>
        </>
    );
}
