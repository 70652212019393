import {
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material"
import React, { useEffect, useState } from "react"
import Row from "./TableRow"
import AddNewSample from "./AddNewSample"
import Popup from "../../components/popup"
import { Cancel, WarningAmberOutlined } from "@mui/icons-material"
import { getAppConfig } from "../../utils/config"
import { useAuth0 } from "@auth0/auth0-react"
import { useTrovoConfig } from "../../utils/config"

type FileData = {
    id: string;
    name: string;
    status?: string;
    lane_number?: number;
    read_number?: number;
};

type SampleData = {
    number_of_files?: any;
    name: string;
    other_categories: any;
    files?: Array<FileData>;
    id: string;
};

type SelectedSample = {
    value: string;
};

type ManageSamplesTableProps = {
    data: any
    exp: any
    adding: boolean
    setAdding: any
    setDeleteCallback: any
    selectedSamples: any
    selectAllChecked: any
    handleSelectAllClick: any
    handleSelectRow: any
    isDuplicationStarted: any
    handleRemoveSample: any
    selectedSamplesArray: any
    setDuplicationStarted: any
    reFetchSamples: any
};

export default function ManageSamplesTable(props: ManageSamplesTableProps) {
    const {
        data,
        exp,
        adding,
        setAdding,
        setDeleteCallback,
        selectedSamples,
        selectAllChecked,
        handleSelectAllClick,
        handleSelectRow,
        isDuplicationStarted,
        handleRemoveSample,
        selectedSamplesArray,
        setDuplicationStarted,
        reFetchSamples
    } = props
    const { apiHost } = getAppConfig()
    const { getAccessTokenSilently } = useAuth0()
    const { user } = useTrovoConfig()
    const [deletePopup, setDeletePopup] = useState(false)

    const triggerDelete = () => {
        if (selectedSamples.size > 0) {
            setDeletePopup(true)
        }
    }

    const handleDelete = async () => {
        try {
            const accessToken = await getAccessTokenSilently()

            const deletePromises = Array.from(selectedSamples).map(s => {
                return fetch(`${apiHost}/experiment/${exp.id}/sample/${s}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })
            })
            const responses = await Promise.all(deletePromises)
            setDeletePopup(false)
            reFetchSamples()
        } catch (error) {
            console.error("Error during deletion:", error)
        }
    }

    const getSelectedSamples = (data: SampleData[], selectedSamples: SelectedSample[]): SampleData[] => {
        const selectedIds = selectedSamples.map((s: SelectedSample) => s.value)

        return data.filter((sample: SampleData) => selectedIds.includes(sample.id));
    }

    const filteredData = getSelectedSamples(data || [], selectedSamplesArray || [])

    useEffect(() => {
        setDeleteCallback(() => triggerDelete);
    }, [selectedSamples]);

    useEffect(() => {
        const shouldSetDuplicationStarted = !filteredData.length && isDuplicationStarted;
        if (shouldSetDuplicationStarted) {
            setDuplicationStarted(false);
        }
    }, [filteredData.length, isDuplicationStarted]); // Ensure dependencies are correctly specified


    return (
        <TableContainer sx={{ overflow: "hidden", borderRadius: "9px", border: "1px solid", borderColor: "outline" }}>
            {adding && <AddNewSample
                data={data}
                exp={exp}
                setAdding={setAdding}
                sampleSource={data[0]}
                reFetchSamples={reFetchSamples}
            />}

            {isDuplicationStarted && filteredData.map((sample, index) => (
                <AddNewSample
                    key={index}
                    data={data}
                    exp={exp}
                    setAdding={setAdding}
                    isDuplicationStarted={isDuplicationStarted}
                    sampleSource={sample}
                    onRemoveSample={handleRemoveSample}
                    reFetchSamples={reFetchSamples}
                />
            ))}
            <Table aria-label="collapsible table" sx={{ "th, td": { border: 0 } }}>
                <TableHead sx={{ th: { bgcolor: "surface-container-high", height: "25px", py: 0 } }}>
                    <TableRow>
                        <TableCell />
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={selectAllChecked}
                                disabled={user.cb_item_price_id?.includes("demo")}
                                onChange={handleSelectAllClick}
                                size="small"
                            />
                        </TableCell>
                        <TableCell>Sample Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Organism</TableCell>
                        {data?.length ? (
                            Object.keys(data[0]?.other_categories || {}).map((k: any) => {
                                return <TableCell key={k}>{k}</TableCell>
                            })
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && !!data.length ? (
                        data
                            .sort(
                                (a: any, b: any) => Number(new Date(b.created_time)) - Number(new Date(a.created_time))
                            )
                            .map((row: { id: any }) => (
                                <Row
                                    key={row?.id}
                                    exp={exp}
                                    row={row}
                                    selected={selectedSamples.has(row?.id)}
                                    onClick={() => handleSelectRow(row?.id)}
                                    refetch={reFetchSamples}
                                />
                            ))
                    ) : (
                        <TableRow>
                            <TableCell />
                            <TableCell /> <TableCell sx={{ textAlign: "center" }}>No results to display</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {deletePopup && (
                <Popup isOpen={true} onClose={() => {}}>
                    <Box
                        py={5}
                        px="8%"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            cursor: "pointer",
                            gap: 3
                        }}>
                        <WarningAmberOutlined sx={{ fontSize: "60px", color: "#BA1A1A", my: 1, alignSelf: "center" }} />
                        <Typography variant="title" size="large">
                            Are you sure you want to delete these samples?
                        </Typography>
                        <Typography variant="body" size="medium">
                            This action cannot be undone and will permanently delete these samples from your experiment.
                            Deleting these files will not affect any analyses you've already run, but these samples
                            cannot be included in future analyses.
                        </Typography>
                        <Typography variant="body" size="medium">
                            Do you still want to delete the selected samples?
                        </Typography>
                        <Button variant="contained" onClick={handleDelete}>
                            Delete ({selectedSamples.size}) sample{`${selectedSamples.size > 1 ? "s" : ""}`}
                        </Button>
                        <Button endIcon={<Cancel />} onClick={() => setDeletePopup(false)}>
                            Nevermind, cancel
                        </Button>
                    </Box>
                </Popup>
            )}
        </TableContainer>
    )
}
