import {
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import React, { ReactNode } from "react";

type InputWithSelectProps = {
  title: string;
  inputVal: string | number;
  inputName?: string;
  update: (update: { name: string; value: string | number }) => void;
  options: string[] | number[];
  selectLabel: string;
  selectName?: string;
  optionVal: string | number;
  selectCustomRender?: (val: string | number) => ReactNode;
};

export function InputWithSelect({
  title,
  inputVal,
  inputName,
  update,
  options,
  selectName,
  selectLabel,
  optionVal,
  selectCustomRender,
}: InputWithSelectProps) {
  const theme = useTheme();
  return (
    <>
      <Grid
        display={"flex"}
        alignContent={"center"}
        container
        direction="row"
        wrap={"nowrap"}
      >
        <Grid item>
          <InputLabel
            variant="standard"
            id={inputName}
            sx={{ ...theme.styles.label.medium }}
          >
            {title}
          </InputLabel>
          <Input
            sx={{
              height: 30,
              "& .MuiFormLabel-root": theme.styles.label.medium,
            }}
            id={selectName}
            name={inputName}
            type="text"
            value={inputVal}
            onChange={(e) =>
              update({ name: e.target.name, value: e.target.value })
            }
          />
        </Grid>
        <Grid item>
          <InputLabel
            variant="standard"
            id={selectName}
            sx={{ ...theme.styles.label.medium }}
          >
            {selectLabel}
          </InputLabel>
          <Select
            variant="standard"
            sx={{ height: 30 }}
            id={selectName}
            label={selectLabel}
            name={selectName}
            renderValue={selectCustomRender}
            value={optionVal}
            onChange={(e) =>
              update({ name: e.target.name, value: e.target.value })
            }
          >
            {options.map((o) => {
              return (
                <MenuItem key={o} value={o}>
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
    </>
  );
}
