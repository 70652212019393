import { useState, useRef, useEffect } from "react";
import ChargebeeComponents from "@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup";
import { AlertColor } from "@mui/material";

interface LocalDataErrors {
  number?: string;
  expiry?: string;
  cvv?: string;
  error?: string;
}

export const useCCForm = (
  submitFormHandler: (token: string) => Promise<string>,
  setSnackbar: ({
    open,
    message,
    severity,
  }: {
    open: boolean;
    message: string;
    severity: AlertColor;
  }) => void,
) => {
  const [formErrors, setFormErrors] = useState<LocalDataErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [fieldStatus, setFieldStatus] = useState({
    numberComplete: false,
    expiryComplete: false,
    cvvComplete: false,
  });
  const cardRef = useRef<ChargebeeComponents | null>(null);
  const [name, setName] = useState("");

  useEffect(() => {
    if (
      fieldStatus.numberComplete &&
      fieldStatus.expiryComplete &&
      fieldStatus.cvvComplete &&
      name.trim().length > 0
    ) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [
    fieldStatus.numberComplete,
    fieldStatus.expiryComplete,
    fieldStatus.cvvComplete,
    name,
  ]);

  const handleFormSubmit = () => {
    if (!cardRef.current) return;
    setIsLoading(true);
    cardRef.current
      .tokenize({})
      .then((data: any) => {
        console.log("data", data);
        submitFormHandler(data.token)
          .then((status) => {
            if (status === "Error") {
              console.log("ERROR: ", status);
              setIsLoading(false);
              setSnackbar({
                open: true,
                message: "Error submitting payment details",
                severity: "error",
              });
              setFormErrors({ error: "Error submitting payment details" });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log("ERROR: ", error);
            setSnackbar({
              open: true,
              message: "Error submitting payment details",
              severity: "error",
            });
            setFormErrors({ error: "There was a problem with your card" });
          });
      })
      .catch((error: any) => {
        // This error trips when card tokenization fails. Request never gets made to backend.
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: "Error submitting payment details",
          severity: "error",
        });
        console.log("TOKENIZE ERROR: ", error);
        setFormErrors({ error: "There was an problem with your card (3009)" });
      });
  };

  // useEffect(() => {
  //     console.log("formErrors: ", formErrors)
  // }, [formErrors])

  const handleFormChange = (evt: any) => {
    // console.log(evt);
    if (
      evt.field === "number" ||
      evt.field === "expiry" ||
      evt.field === "cvv"
    ) {
      if (evt.complete && !evt.error) {
        setFieldStatus((state) => ({
          ...state,
          [`${evt.field}Complete`]: true,
        }));
      } else {
        setFieldStatus((state) => ({
          ...state,
          [`${evt.field}Complete`]: false,
        }));
      }
      if (evt.error) {
        setFormErrors((state) => ({
          ...state,
          [evt.field]: evt.error.message,
        }));
      } else {
        setFormErrors((state) => ({ ...state, [evt.field]: "" }));
      }
    }
  };

  return {
    handleFormChange,
    isComplete,
    isLoading,
    setIsLoading,
    cardRef,
    formErrors,
    handleFormSubmit,
    name: { name, setName },
  };
};
