import React, { useEffect, useState } from 'react';
import { Button, Box, Typography, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getAppConfig } from "../../utils/config";
import { useAuth0 } from "@auth0/auth0-react";


type FilePropTypes = {
    alias: string;
    created_by: string;
    created_time: string;
    ex: any;
    ex_id: string;
    ex_time: string;
    ex_user: string;
    fastqc_uri: null,
    id: string;
    interleaved: boolean;
    lane_number?: string;
    last_segment: any;
    name?: string;
    original_size: number | null;
    read_number?: string;
    sample_id?: string;
    status?: string;
    strandedness_seq_end?: string;
    strandedness_seq_sense?: string;
    uri?: string;
    setEdit?: any;
}  

export default function EditMetaForm({file, expId, sId, setEdit, refetch }:FilePropTypes | any) { 
    const { name, lane_number, read_number, alias, id, strandedness_seq_end } = file;
    const [fileName, setFileName] = React.useState(name);
    const [laneNum, setLaneNum] = React.useState(lane_number);
    const [readNum, setReadNum] = React.useState(read_number);
    const [ali, setAli] = React.useState(alias || name);
    const [message, setMessage] = React.useState<any>(null);

    const { apiHost } = getAppConfig();
    const { getAccessTokenSilently } = useAuth0();

    const laneOptions = ["1", "2", "3", "4"];
    const readOptions = strandedness_seq_end === 'paired' ? ["1", "2"] : ["1"];
console.log(laneNum, readNum)
useEffect(() => {
setMessage(null)
}, [fileName, laneNum, readNum, ali])

const saveAndClose = () => {
  setEdit(null) 
  refetch ? refetch() : window.location.reload()
}
    const handleSave = async () => {
        const body = { name: fileName, lane_number:laneNum, read_number: readNum, alias: ali }
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(`${apiHost}/experiment/${expId}/sample/${sId}/file/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(body)
        });
    response.status !== 200 ? setMessage('There was an error saving your changes. Please try again. If the problem persists, please contact support@trovomics.com ') : ( saveAndClose() )
      } catch (error) {
        console.error(error)
      }
    };
  
    const handleCancel = () => {
        setEdit(null)
    };
  
    return (
      <Box p={3} >
        <Typography variant="headline" size='medium' pb={2} >
          Edit file metadata
        </Typography>
        <Typography variant='body' size='medium' pb={2} >
        Metadata is automatically extracted from the sequencing data file name. This is the recommended setup for this file. Changing file metadata is not recommended in most instances. 
        </Typography>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          margin="normal"
        />
        
        <TextField
          fullWidth
          label="Alias" 
          variant="outlined"
          value={ali} 
          onChange={(e) => setAli(e.target.value)}
          margin="normal"
        />
  
  <FormControl fullWidth margin="normal">
                <InputLabel>Lane number</InputLabel>
                <Select
                    value={laneNum}
                    onChange={(e) => setLaneNum(e.target.value)}
                    label="Lane number"
                >
                    {laneOptions.map(option => (
                        <MenuItem key={option} value={option}>{'L00'+option}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
                <InputLabel>Read number</InputLabel>
                <Select
                    value={readNum}
                    onChange={(e) => setReadNum(e.target.value)}
                    label="Read number"
                >
                    {readOptions.map(option => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                </Select>
            </FormControl>
  
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
          <Button variant="outlined" onClick={handleCancel}>
            Nevermind, cancel
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save and update
          </Button>
        </Box>
        {message && <Typography mx={2} pt={1} variant='body' size='large' color='error' align='center' >{message}</Typography> }
      </Box>
    );
}