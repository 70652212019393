import Stack from "@mui/material/Stack";
import React from "react";
import { GeneralLayoutController } from "../GeneralLayoutController";
import { GeneralGraphProps } from "../../../types/GraphTypes";

type GOControllerLayoutProps = {
  title: string;
  update: Function;
  fontSize: number;
  yTitle: string;
  width: number;
  height: number;
};
export function GOControllerLayout(
  props: GOControllerLayoutProps & GeneralGraphProps,
) {
  const { update } = props;
  return (
    <Stack>
      <GeneralLayoutController {...props} />
    </Stack>
  );
}
