import { GeneralGraphProps } from "../../../types/GraphTypes";

export * from "./dataProcessing";
export * from "./VolcanoControler";
export * from "./VolcanoControlsLayout";

export type VolcanoMark =
  | "Normal"
  | "Not Significant"
  | "Upregulated"
  | "Downregulated";

export type VolcanoColorMap = {
  [k in VolcanoMark]: string;
};
export interface VolcanoProps extends GeneralGraphProps {
  compare: string[];
  compareList: string[];
  pchoice: "padj" | "pvalue";
  pcut: number;
  FCcut: number;
  max: number;
  markSize: number;
  colorMap: VolcanoColorMap;
}

const colorMap: VolcanoColorMap = {
  Upregulated: "#FF0000",
  Downregulated: "#0000FF",
  "Not Significant": "#777777",
  Normal: "#000000",
};
export const volcanoConfig: VolcanoProps = {
  colorMap: colorMap,
  xTitle: "logfold",
  title: "Volcano Plot",
  xTitlePos: 0,
  yTitlePos: 0.9,
  yTitle: "yValue",
  pchoice: "padj" as "padj" | "pvalue",
  compare: [],
  compareList: [],
  width: 720,
  height: 400,
  pcut: 0.05,
  FCcut: 2,
  max: 15,
  fontSizeTitle: 20,
  fontSizeX: 12,
  fontSizeY: 12,
  markSize: 5,
  showGrid: false,
};
