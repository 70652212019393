import React, { useState } from "react"
import { Box, Typography, Accordion, AccordionSummary, Button, TextField, MenuItem } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { Close, Highlight, HighlightOutlined } from "@mui/icons-material"

interface Data {
    [key: string]: string[]
}

interface StepProps {
    formData: {
    }
    setFormData: any
    handleNext: any
}

const mockDiffExpData = [
    {
        "category": "Parameters for differential expression (DE) and normalization",
        "parameters": [
            {
                "type": "Normalization method",
                "description": "Select a method to normalize your data. `Vst` is recommended for larger datasets.",
                "value": "Regulated log transformation (rld, rlog)",
                "options": [
                    "Regulated log transformation (rld, rlog)",
                    "Variance stabilizing transformation (vst)"
                ]
            },
            {
                "type": "Metric to filter counts",
                "description": "Select a metric to remove genes with low counts and determine the threshold for cutoff e.g., A metric of `mean count` and threshold of `5` will remove all genes that have a mean read count below five. A metric of `max count` and threshold of `10` will remove all genes that have a maximum read count below 10.",
                "value": "mean raw count",
                "options": ["mean raw count", "minimum raw count", "maximum raw count", "minimum normalized count", "mean normalized count", "maximum normalized count", "percent samples with 0 counts", "Percent Samples with Low Counts (< 5)"  ]
            },
            {
                "type": "Low count threshold",
                "description": "Select a threshold for low counts.",
                "value": 5,
                "min": 0,
                "max": 99999
            },
            {
                "type": "Method to adjust P values",
                "description": "Select a method to adjust your P values. This helps account for false positives.",
                "value": "Benjamini-Hochberg (BH)",
                "options": ["Benjamini-Hochberg (BH)", "False Discovery Rate (FDR)", "Benjamini-Yekutieli (BY)",'Holm', 'Hochberg', 'Hommel', 'Bonferroni', 'none']
            },
            {
                "type": "Shrink Log2Fold changes",
                "description": "Choose whether you want to shrink log2foldchanges.",
                "value": "False",
                "options": ["True", "False"]
            }
        ]
    },
    {
        "category": "Parameters for ontology, and initial filtering of significant genes",
        "parameters": [
            {
                "type": "P Value Source",
                "description": "Select whether to determin significance from P values or adjusted P values.",
                "value": "Adjusted P value",
                "options": [
                    "Adjusted P value",
                    "P value"
                ]
            },
            {
                "type": "P Value Cutoff",
                "description": "Enter the cutoff for P value. Only genes with a P value below this threshold will be included.",
                "value": 0.05,
                "min": 0,
                "max": 1
            },
            {
                "type": "Fold Change Cutoff",
                "description": "Enter the cutoff for fold change. Only genes with an absolute fold change above this threshold will be included.",
                "value": 2,
                "min": 1,
                "max": 99999
            },
            {
                "type": "Separate GO Analysis for Up vs Downregulated Genes",
                "description": "Choose whether you want to separate differentially expressed genes by their direction in fold change into different gene lists (i.e., separate upregulated and downregulated genes).",
                "value": "False",
                "options": ["True", "False"]
            },
            {
                "type": "Ontology P Value Cutoff",
                "description": "Enter the cutoff for P value for GO analysis. Only GO terms with a P value below this threshold will be included.",
                "value": 0.05,
                "min": 0,
                "max": 1
            },
            {
                "type": "Ontology Method to Adjust P Values",
                "description": "Select a method to adjust your P values for your GO terms",
                "value": "False Discovery Rate (FDR)",
                "options": ["Benjamini-Hochberg (BH)", "False Discovery Rate (FDR)", "Benjamini-Yekutieli (BY)",'Holm', 'Hochberg', 'Hommel', 'Bonferroni', 'none']
            }
        ]
    }
]


const EditSettings: React.FC<any> = ({ formData, setFormData }) => {
    const initialData = mockDiffExpData.map(section => ({
        ...section,
        open: true,
        editMode: false,
    }));

    const [dataState, setDataState] = useState(initialData);

    const mapping:  { [key: string]: string } = {
        "Normalization method": "normalization_method",
        "Metric to filter counts": "filter_source",
        "Low Count Threshold": "filter_threshold",
        "Method to adjust P values": "DE_padjust_method",
        "Shrink Log2Fold Changes": "lfc_shrinkage",
        "P Value Source": "pvalue_option", 
        "P Value Cutoff": "pvalue_threshold",
        "Fold Change Cutoff": "fold_change_threshold",
        "Separate GO Analysis for Up vs Downregulated Genes": "if_separate_regulation",
        "Ontology P Value Cutoff": "ontology_pvalue_cutoff",
        "Ontology Method to Adjust P Values": "GO_padjust_method"
    };

    const handleInputChange = (sectionIndex: number, name: string, newValue: string) => {
        const updatedData = [...dataState];
        const parameterIndex = updatedData[sectionIndex].parameters.findIndex(param => param.type === name);
        updatedData[sectionIndex].parameters[parameterIndex].value = newValue;

        setDataState(updatedData);
        setFormData((prevState: any) => ({
            ...prevState,
            [mapping[name]]: newValue
        }));
    };

    const handleUpdateSettings = (index: number) => {
        setDataState(prevData => 
            prevData.map((section, i) => {
                if (i === index) {
                    return { ...section, editMode: false };
                }
                return section;
            })
        );
    };
    return (
        <Box display="flex" flexDirection="column" gap="30px" marginBottom="24px" bgcolor={'surface-container-lowest'} p={3} borderRadius={'12px'}>
            <Box display={'flex'} gap={2} px={3} m={1} alignItems={'center'} >
                <HighlightOutlined sx={{ fontSize:'42px', color:'on-surface' }} />
                <div>
                <Typography variant="title" size="medium">Setting custom parameters</Typography>
                <Typography variant="body" size="medium">We provide a set of default parameters for analyses. These can be modified now or during re-analysis. To learn more about why or how you might changes these parameters, check out our Help Center resources.</Typography>
                </div>
                
            </Box>
            {dataState.map((section, i) => (
                <Box borderRadius="10px" border="1px solid" borderColor="outline-variant" overflow="hidden">
                    <Accordion
                        expanded={section.open}
                        sx={{
                            backgroundColor: "unset",
                            boxShadow: "unset",
                            "& .MuiAccordionSummary-root, .MuiAccordionSummary-root.Mui-expanded": {
                                minHeight: "0"
                            },
                            "& .MuiAccordionSummary-root.Mui-expanded": {
                                borderBottom: "1px solid",
                                borderColor: "outline-variant"
                            }
                        }}>
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            onClick={() =>
                                setDataState(prevData =>
                                    prevData.map((item, idx) => {
                                        if (idx === i) {
                                            return { ...item, open: !item.open };
                                        }
                                        return item;
                                    })
                                )
                            }
                            sx={{
                                backgroundColor: "surface-container-highest",
                                padding: "4px 4px 4px 16px",
                                height: "0",
                                minHeight: "0",
                                gap: "12px",
                                flexDirection: "row-reverse",
                                alignItems: "center",
                                "& .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
                                    alignItems: "center",
                                    margin: 0
                                },
                                "& .MuiAccordionSummary-root.Mui-expanded": {}
                            }}>
                             <Typography variant="title" size="medium" width="100%">
                                {section.category}
                            </Typography>
                            <Button
                                startIcon={section.editMode ? <Close /> : <EditOutlinedIcon />}
                                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    e.stopPropagation();
                                    setDataState(prevData =>
                                        prevData.map((item, idx) => {
                                            if (idx === i) {
                                                return { ...item, editMode: !item.editMode };
                                            }
                                            return item;
                                        })
                                    );
                                }}>
                                {section.editMode ? 'Cancel' : 'Edit'}
                            </Button>
                        </AccordionSummary>
                        {section.editMode ? (
                             <Box display="flex" flexDirection="column" gap="36px" padding="24px">
                             {section.parameters.map((item, paramIndex) => {
                                 if (item.options) { 
                                     return (
                                         <TextField
                                             select
                                             label={item.type}
                                             helperText={item.description}
                                             value={item.value}
                                             onChange={(e) => handleInputChange(i, item.type, e.target.value)}>
                                             {item.options.map(option => (
                                                 <MenuItem key={option} value={option}>
                                                     {option}
                                                 </MenuItem>
                                             ))}
                                         </TextField>
                                     );
                                 } else {
                                    const inputProps = {
                                        min: item.min,
                                        max: item.max,
                                        step: item.max > 1 ? 0.5 : .05
                                    };
                                     return (
                                         <TextField
                                            type="number"
                                             label={item.type}
                                             helperText={item.description}
                                             value={item.value}
                                             onChange={(e) => handleInputChange(i, item.type, e.target.value)}
                                             inputProps= {inputProps}
                                         />
                                     );
                                 }
                             })}
                             <Button sx={{ borderRadius:'45px'}} variant="contained" color="primary" onClick={() => handleUpdateSettings(i)}>Update Settings</Button>
                         </Box>
                        ) : (
                            <Box display="flex" flexDirection="column" gap="15px" padding="20px">
                                {section.parameters.map(item => (
                                    <Box display="flex" gap="24px">
                                        <Typography variant="title" size="medium" width="400px">
                                            {item.type}
                                        </Typography>
                                        <Typography variant="body" size="large" width={310} >
                                            {item.value}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Accordion>
                </Box>
            ))}
        </Box>
    );
};

export default EditSettings