import React, { ChangeEvent, useEffect, useState } from "react"
import { Typography, Box, Grid, IconButton, styled, Button } from "@mui/material"
import SampleTemplate from "../SampleTemplate"
import Papa from "papaparse"
import { useNavigate, useParams } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { getAppConfig, useTrovoConfig } from "../../../../utils/config"
import { Summarize, FileDownload, FileUpload, TextSnippet, HighlightOff, CircleOutlined, CheckCircleOutlineOutlined } from "@mui/icons-material"
import SampleBulkUpload from "./SampleBulkUpload"
import Step3HasSamples from "./Step3HasSamples" 

const Step3Index = ({ experiment, createSamples, samples, setStep }: any) => {

    const csvRef = React.useRef<HTMLInputElement>(null)
    const fastqRef = React.useRef<HTMLInputElement>(null)
    const { id } = useParams()
    const { user } = useTrovoConfig()
    const { getAccessTokenSilently } = useAuth0()
    const { apiHost } = getAppConfig()
    const navigate = useNavigate()
    const [showUploader, setShowUploader] = useState(false)
    const [pageState, setPageState] = useState<{ csv: { file: File; contents: any }; fastq_files: File[] }>({
        csv: { file: {} as File, contents: {} },
        fastq_files: []
    })

    const complete = Boolean(pageState.csv.file?.name && pageState.fastq_files.length > 0)

    const handleDownloadClick = () => {
        const blob = new Blob([SampleTemplate], { type: "text/csv" })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download = "sampleTemplate.csv"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
    }

    const handleSelectFile = (type: "csv" | "fastq") => {
        if (type === "csv") {
            csvRef.current?.click()
        } else {
            fastqRef.current?.click()
        }
    }

    const handleRemoveFile = (type: "csv" | "fastq", fileName?: string) => {
        if (type === "csv") {
            setPageState((state: any) => ({ ...state, csv: { file: {}, contents: {} } }))
        } else {
            setPageState((state: any) => ({
                ...state,
                fastq_files: state.fastq_files.filter((file: any) => file.name !== fileName)
            }))
        }
    }

    const handleCSVUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const filesList = event.target.files
        if (!filesList || !filesList.item(0) || filesList?.length === 0) return

        Papa.parse(filesList.item(0)!, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
            complete: (result: any) => {
                if (result.errors.length > 0) {
                    console.log("error", result.errors)
                }

                setPageState((state: any) => ({ ...state, csv: { file: filesList.item(0), contents: result.data } }))
            }
        })
    }

    const handleFASTQUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const filesList = event.target.files
        if (!filesList || filesList?.length === 0) return
        let files: File[] = []
        for (let i = 0; i < filesList.length; i++) {
            files.push(filesList[i])
        }
        setPageState((state: any) => ({ ...state, fastq_files: [...state.fastq_files, ...files] }))
    }

    const isIlluminaFormat = (filename: string) => {
        const filenameParts = filename.split("_")
        if (filenameParts.length < 5) return false
        const regex = new RegExp(/^[a-zA-Z0-9-_.]+_S[1-9][0-9]{0,7}_L00[1-4]_R[1-2]_001.fastq.gz$/)
        const regexResult = regex.test(filename)
        return regexResult
    }

    const isNCBIGeoFormat = (filename: string) => {
        console.log("checking isNCBIGeoFormat for filename: ", filename)
        const filenameParts = filename.split("_")
        if (experiment.sequencing_read_type === "Paired-end") {
            if (filenameParts.length !== 2) return false
            const regex = new RegExp(/^SRR[1-9][0-9]{3,7}_[1-2].fastq.gz$/)
            const regexResult = regex.test(filename)
            console.log("regexResult: ", regexResult)
            return regexResult
        }
        if (experiment.sequencing_read_type === "Single-end") {
            if (filenameParts.length !== 1) return false
            const regex = new RegExp(/^SRR[1-9][0-9]{3,7}.fastq.gz$/)
            const regexResult = regex.test(filename)
            return regexResult
        }
    }

    const handleSaveAndContinue = () => {
        const columns = Object.keys(pageState.csv!.contents[0])
        const uniqueValuesByCategoryWithDeletions: {
            [key: string]: string[]
        } = {}
        const uniqueValuesByCategory: {
            [key: string]: string[]
        } = {}
        columns.forEach((column: string) => {
            uniqueValuesByCategory[column] = Array.from(new Set(pageState.csv!.contents.map((row: any) => row[column])))
            uniqueValuesByCategoryWithDeletions[column] = Array.from(
                new Set(pageState.csv!.contents.map((row: any) => row[column]))
            )
        })
        const sampleNames = uniqueValuesByCategory["SampleName"]
        const filenames = uniqueValuesByCategory["Filename"]

        // array with a list of categories (as strings) with more than one value. Just get the list of category names
        const categoriesWithMoreThanOneValue: any = []
        for (const category in uniqueValuesByCategory) {
            if (uniqueValuesByCategory[category].length > 1) {
                categoriesWithMoreThanOneValue.push(category)
            }
        }
        // if category in uniqueValuesByCategoryWithDeletions has only one value, delete it from uniqueValuesByCategoryWithDeletions
        for (const category in uniqueValuesByCategoryWithDeletions) {
            if (uniqueValuesByCategoryWithDeletions[category].length === 1) {
                delete uniqueValuesByCategoryWithDeletions[category]
            }
        }

        delete uniqueValuesByCategoryWithDeletions["Filename"]
        delete uniqueValuesByCategoryWithDeletions["SampleName"]

        const filenameDedupedSamples: any = []
        for (const row of pageState.csv!.contents) {
            if (filenameDedupedSamples.some((obj: any) => obj.Filename === row.Filename)) continue
            filenameDedupedSamples.push(row)
        }

        const dedupedAndCleanedSampleFiles = filenameDedupedSamples.filter((row: any) => {
            return (
                pageState.fastq_files.some((file: any) => file.name === row.Filename) &&
                (isIlluminaFormat(row.Filename) || isNCBIGeoFormat(row.Filename))
            )
        })

        const dedupedAndCleanedFilesBySample: any = {}
        for (const row of dedupedAndCleanedSampleFiles) {
            if (!dedupedAndCleanedFilesBySample[row.SampleName]) {
                dedupedAndCleanedFilesBySample[row.SampleName] = []
            }
            dedupedAndCleanedFilesBySample[row.SampleName].push(row.Filename)
        }

        const dedupedAndCleanedFilesBySampleWithEmptyArrays: any = {}
        // add empty arrays for samples that don't have any files
        for (const sampleName of uniqueValuesByCategory["SampleName"]) {
            if (!dedupedAndCleanedFilesBySample[sampleName]) {
                dedupedAndCleanedFilesBySampleWithEmptyArrays[sampleName] = []
            } else {
                dedupedAndCleanedFilesBySampleWithEmptyArrays[sampleName] = dedupedAndCleanedFilesBySample[sampleName]
            }
        }

        const rawFirstOfEachUniqueSample: any[] = []
        for (const row of pageState.csv.contents) {
            if (rawFirstOfEachUniqueSample.some((obj: any) => obj.SampleName === row.SampleName)) continue
            // delete row["Filename"]
            rawFirstOfEachUniqueSample.push(row)
        }

        // remove unused columns
        const firstOfEachUniqueSample = rawFirstOfEachUniqueSample.map((row: any) => {
            const columns = Object.keys(row)
            columns.forEach((column: string) => {
                if (!categoriesWithMoreThanOneValue.includes(column)) {
                    delete row[column]
                }
            })
            return row
        })

        // create an array with all values from all categories in the uniqueValuesByCategoryWithDeletions object
        const allValuesFromAllCategories: any[] = []
        for (const category in uniqueValuesByCategoryWithDeletions) {
            allValuesFromAllCategories.push(...uniqueValuesByCategoryWithDeletions[category])
        }

        // list of files from dedupedAndCleanedFilesBySample
        const filesFromDedupedAndCleanedFilesBySample: any[] = []
        for (const sampleName in dedupedAndCleanedFilesBySample) {
            filesFromDedupedAndCleanedFilesBySample.push(...dedupedAndCleanedFilesBySample[sampleName])
        }

        createSamples({
            ...pageState,
            values_by_sample: firstOfEachUniqueSample,
            files_list: dedupedAndCleanedFilesBySample
        })
    }

    return (
        <Box>
        {experiment?.sample_count > 0 && samples?.length && !showUploader ? ( <Step3HasSamples samples={samples} setStep={setStep} setShowUploader={setShowUploader} />  ) : (
        <> 
        <SampleBulkUpload pageState={pageState} handleDownloadClick={handleDownloadClick} handleSelectFile={handleSelectFile} handleRemoveFile={handleRemoveFile} handleCSVUpload={handleCSVUpload} csvRef={csvRef} handleFASTQUpload={handleFASTQUpload} fastqRef={fastqRef} />
         <Button variant="contained" color="primary" onClick={handleSaveAndContinue} disabled={!complete} fullWidth>
             Save and Continue
         </Button>
         <Button variant="text" color="primary" onClick={() => navigate(`/`)} fullWidth>
             Save Progress and Exit
         </Button>
         </> )}
     </Box>

    )
}

export default Step3Index;