export function getGeneLists(data: any) {
  const list = data.visSessionInfo.data.gene_lists;
  // @ts-ignore
  return Object.keys(list).filter((l) => l !== "All-Genes");
}

export function getComparePlot({ data, geneList }: any) {
  if (geneList.length < 2) return { data: [], layout: {} };
  const v = Object.values(data.visSessionInfo.data);
  if (!v.length) return;
  // @ts-ignore
  const list = v[0].gene_lists;
  let same = 0;
  geneList.forEach((g: any, i: number) => {
    if (!list[g]) {
      return;
    }
    if (i + 1 === geneList.length) {
      return;
    }
    const nextK = geneList[i + 1];
    list[g].List.forEach((g: string) => {
      if (list[nextK].List.includes(g)) {
        same += 1;
      }
    });
  });
  const plot = {
    x: [1, 1.75, 2.5],
    y: [1, 1, 1],
    type: "scatter",
    mode: "text",
    text: [list[geneList[0]].Count, same, list[geneList[1]].Count],
    textfont: {
      color: "black",
      size: 18,
      family: "Arial",
    },
  };

  const layout = {
    title: "Compare",
    xaxis: {
      showticklabels: false,
      autotick: false,
      showgrid: false,
      zeroline: false,
    },
    yaxis: {
      showticklabels: false,
      autotick: false,
      showgrid: false,
      zeroline: false,
    },
    shapes: [
      {
        opacity: 0.3,
        xref: "x",
        yref: "y",
        fillcolor: "blue",
        x0: 0,
        y0: 0,
        x1: 2,
        y1: 3,
        type: "circle",
        line: {
          color: "blue",
        },
      },
      {
        opacity: 0.3,
        xref: "x",
        yref: "y",
        fillcolor: "gray",
        x0: 1.5,
        y0: 0,
        x1: 3.5,
        y1: 3,
        type: "circle",
        line: {
          color: "gray",
        },
      },
    ],
    margin: {
      l: 20,
      r: 20,
      b: 100,
    },
    height: 400,
    width: 480,
  };
  return {
    data: [plot],
    layout,
  };
}
