import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Tabs,
  Tab,
  Stack,
  Box,
  Card,
  Container,
  IconButton,
  CircularProgress,
  useTheme,
  Typography,
  CardContent,
  Button,
  Modal,
  Divider,
  Tooltip,
  Paper,
} from "@mui/material";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Lasso from "@mui/icons-material/Gesture";
import PanToolIcon from "@mui/icons-material/OpenWith";
import CottageIcon from "@mui/icons-material/Home";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SquareSelect from "@mui/icons-material/HighlightAlt";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { useVisData, VisData } from "../utils/database";
import { groupByCompare } from "../components/graphs/Volcano";
import { geneListsToMap, mapGOCompare } from "../components/graphs/GO";
import { DownloadPlotModal } from "../components/modals/DownloadPlotModal";
import { GraphControlsDrawer } from "../components/drawers/GraphControlsDrawer";
import Plotly, { PlotlyHTMLElement } from "plotly.js";
import { CustomPlot } from "../components/graphs/CustomPlot";
import { usePlots } from "../hooks/usePlots";
import { SvgIconComponent } from "@mui/icons-material";
import DataTable from "../components/SelectionTable";

const ToolButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "white",
  },
  "&:focus": {
    outline: "none",
  },
}));
type ToolButtonWithTipProps = {
  tip: string;
  tool: string;
  ToolIcon: SvgIconComponent;
  selected: boolean;
  onClick: Function;
};

function ToolButtonWithTip({
  tip,
  tool,
  ToolIcon,
  selected,
  onClick,
}: ToolButtonWithTipProps) {
  return (
    <Tooltip title={tip} arrow placement={"top"}>
      <ToolButton onClick={() => onClick(tool)}>
        <ToolIcon
          style={{
            color: selected ? "black" : "currentcolor",
          }}
        />
      </ToolButton>
    </Tooltip>
  );
}

const NavTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiButtonBase-root.Mui-selected": {
    color: theme.schemes.light.primary,
    outline: "none",
  },
  "& .indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: theme.schemes.light.primary,
    },
  },
}));

const BlurModal = styled(Modal)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    backdropFilter: "blur(2px)",
  },
}));
const drawerWidth = 340;
export function Visualizer() {
  const theme = useTheme();
  const { id } = useParams();
  const data = useVisData(
    [
      {
        name: "diffExpResults",
        displayName: "Differential expression results",
        process: groupByCompare,
      },
      // {
      //   name: "expressionCount",
      //   displayName: "Gene expression counts",
      // },
      {
        name: "geneOntologyOutput",
        displayName: "Gene ontology outputs",
        process: mapGOCompare,
      },
      {
        name: "visSessionInfo",
        displayName: "Last session info",
        process: geneListsToMap,
      },
      {
        name: "comparisons",
        displayName: "Comparisons data",
      },
    ],
    id || "",
  ) as VisData;
  const [toolOpen, setToolOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const [selections, setSelections] = useState({} as { [key: string]: any });

  const [figure, setFigure] = useState<PlotlyHTMLElement>();

  const {
    tab,
    setTab,
    graphState,
    graphs,
    graph,
    updateState,
    globals,
    updateGlobal,
  } = usePlots(data, figure);

  const getSetFigure = useCallback(function (figure: PlotlyHTMLElement) {
    setFigure(figure);
  }, []);
  // @ts-ignore
  console.log(performance.memory.usedJSHeapSize / 1024 / 1024 / 1024);

  function dragmode(
    mode:
      | false
      | "select"
      | "zoom"
      | "pan"
      | "lasso"
      | "orbit"
      | "turntable"
      | undefined,
  ) {
    updateGlobal("dragmode", mode);
  }

  const dataCount = Object.values(data).reduce((a, c) => {
    if (!c.isLoading) {
      a++;
    }
    return a;
  }, 0);
  return (
    <div>
      <BlurModal open={dataCount !== Object.keys(data).length}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Card sx={{ maxWidth: 345 }}>
            <CardContent>
              <Typography
                style={{
                  ...theme.styles.title.large,
                }}
              >
                Getting everything ready for you 🥰
              </Typography>
              {Object.values(data).map((d) => {
                return (
                  <Box display="flex" alignContent={"center"}>
                    {d.displayName} -{" "}
                    {d.isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <CheckCircleOutlineIcon color={"success"} />
                    )}
                  </Box>
                );
              })}
            </CardContent>
          </Card>
        </Box>
      </BlurModal>

      <>
        <DownloadPlotModal
          open={open}
          setOpen={setOpen}
          figure={figure}
          name={graph.name}
          defaultWidth={graphState.width}
          defaultHeight={graphState.height}
        />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <GraphControlsDrawer
            drawerWidth={drawerWidth}
            ready={!(dataCount !== Object.keys(data).length)}
            globals={globals}
            graph={graph}
            captureUpdate={updateState}
            captureUpdateGlobal={updateGlobal}
            graphProps={graphState}
            open={toolOpen}
            setOpen={setToolOpen}
          />
          <Stack
            style={{
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
              ...(toolOpen && {
                width: `calc(100% - ${drawerWidth}px`,
              }),
              ...(!toolOpen && {
                width: `100%`,
              }),
            }}
          >
            {/*THIS IS THE NAV/Controls*/}
            <Stack>
              <Container maxWidth={false}>
                <Box display={"flex"} justifyContent={"flex-end"} marginTop={2}>
                  <Button
                    sx={{
                      height: 20,
                      color: theme.schemes.light.primary,
                      ...theme.styles.label.large,
                    }}
                    variant={"text"}
                  >
                    <CloseIcon
                      style={{
                        fontSize: 18,
                      }}
                    />
                    Exit analysis
                  </Button>
                </Box>
                <Box>
                  <NavTabs
                    classes={{
                      indicator: "indicator",
                    }}
                    TabIndicatorProps={{ children: <span /> }}
                    value={tab}
                    aria-label="tabs for plot"
                  >
                    {graphs.map((g) => (
                      <Tab
                        value={g.stateKey}
                        onClick={() => {
                          setTab(g.stateKey);
                        }}
                        sx={{
                          color: theme.schemes.light.onSurface,
                          ...theme.styles.title.small,
                        }}
                        label={g.name}
                      />
                    ))}
                  </NavTabs>
                </Box>
                <Divider />
                <Box height={48} display={"flex"} justifyContent={"flex-end"}>
                  <Tooltip title={"Download this plot"} arrow placement={"top"}>
                    <ToolButton onClick={() => setOpen(!open)}>
                      <FileDownloadIcon />
                    </ToolButton>
                  </Tooltip>
                  <ToolButtonWithTip
                    tip={"Lasso select tool"}
                    ToolIcon={Lasso}
                    selected={globals.dragmode === "lasso"}
                    tool={"lasso"}
                    onClick={dragmode}
                  />
                  <ToolButtonWithTip
                    tip={"Square select tool"}
                    ToolIcon={SquareSelect}
                    selected={globals.dragmode === "select"}
                    tool={"select"}
                    onClick={dragmode}
                  />

                  <ToolButtonWithTip
                    tip={"Zoom select"}
                    ToolIcon={ZoomInIcon}
                    selected={globals.dragmode === "zoom"}
                    tool={"zoom"}
                    onClick={dragmode}
                  />
                  <ToolButtonWithTip
                    tip={"Pan tool"}
                    ToolIcon={PanToolIcon}
                    selected={globals.dragmode === "pan"}
                    tool={"pan"}
                    onClick={dragmode}
                  />

                  <ToolButtonWithTip
                    tip={"Reset axes"}
                    ToolIcon={CottageIcon}
                    selected={false}
                    tool={""}
                    onClick={() =>
                      Plotly.relayout(figure!, {
                        "xaxis.autorange": true,
                        "yaxis.autorange": true,
                      })
                    }
                  />
                </Box>
              </Container>
            </Stack>
            <Paper
              style={{
                overflowX: "hidden",
                marginLeft: 24,
                marginRight: 24,
              }}
              variant={"outlined"}
            >
              <CustomPlot
                setSelections={(data: any) => {
                  selections[graph.name] = data;
                  setSelections({ ...selections });
                }}
                setFigure={getSetFigure}
                style={{
                  overflowX: "auto",
                }}
              />
              {selections[graph.name] && selections[graph.name].length > 0 && (
                <DataTable theme={theme} selections={selections[graph.name]} />
              )}
            </Paper>
          </Stack>
        </Stack>
      </>
    </div>
  );
}
