export const displayDictionary: { [k: string]: string } = {
  "-log10(padj)": "-log10 Padj",
  "-log10(pvalue)": "-log10 p-value",
  basemean: "Base Mean",
  comparison: "Comparison",
  ensembl_id: "Ensembl Id",
  foldchange: "Fold Change",
  gene_name: "Gene Name",
  lfcse: "lfcSE",
  log2foldchange: "log2 Fold Change",
  padj: "Padj",
  pvalue: "p-value",
  stat: "Stat",
};
