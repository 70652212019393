import React, { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { FileUpload, TextSnippet } from '@mui/icons-material';
import HighlightOff from '@mui/icons-material/HighlightOff';

type FileUploadBoxProps = {
  maxFiles: number;
  selectedFiles: any;
  onFilesSelected: (files: any) => void;
};

export default function FileUploadBox({ maxFiles, selectedFiles, onFilesSelected }: FileUploadBoxProps) {

  const fastqRef = React.useRef<HTMLInputElement>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      onFilesSelected([...selectedFiles, ...filesArray]);
    }
  };

  const handleSelectFile = () => {
    fastqRef.current?.click()
    onFilesSelected(selectedFiles);
  }

  const handleRemoveFile = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, fileName?: string) => {
    onFilesSelected(selectedFiles.filter((file: any) => file.name !== fileName))
    e.stopPropagation()
  }

  return (
    <Box
      bgcolor="surface-container-low"
      border="1px dashed"
      borderColor="outline-variant"
      justifyContent="center"
      textAlign="center"
      padding="2%"
      sx={{ cursor: "pointer" }}
      onClick={() => handleSelectFile()}>
      {selectedFiles.map((file: any) => (
        <Box px={3} display="flex" justifyContent="space-between" my={1} key={file.name}>
          <Box display="flex" gap="14px">
            <TextSnippet />
            <Typography variant="body" size="large">
              {file.name}
            </Typography>
          </Box>
          <HighlightOff onClick={(e) => handleRemoveFile(e, file.name)} />
        </Box>
      ))}
      {selectedFiles.length < maxFiles ? (
        <Box>
          <FileUpload />
          <Typography variant="body" size="medium">
            Select FastQ Files to Upload
          </Typography>
          <Typography variant="body" size="small">
            Supports .fastq.gz files
          </Typography>
          <input
            ref={fastqRef}
            type="file"
            accept=".fastq, .gz, .fq, .fq.gz, .csv"
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple
          />
        </Box>
      ) : (
        <Box>{''}</Box>
      )
      }
    </Box>
  );
}
