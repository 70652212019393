import { KeyboardArrowUp, KeyboardArrowDown, CloudDownload, ErrorOutline, ArrowRightAlt, Edit, CheckCircleOutline } from "@mui/icons-material";
import { TableRow, TableCell, IconButton, Collapse, Box, Typography, Table, TableHead, TableBody, Checkbox, Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import EditMetaForm from "./EditMetaForm";
import Popup from "../../components/popup";

export default function Row(props: { row: any, refetch: any, exp:any, selected: boolean, onClick: () => void }) {
  const { row, exp, selected, onClick, refetch } = props;
  const [open, setOpen] = useState(false);
  const [editMeta, setEditMeta] = useState(null)

  return (
    <>
      <TableRow
          key={row.id}
          sx={{ borderBottom: '1px solid', borderColor: 'surface-container-low' }}
          data-testid={`sample-item-${row.id}`}
      >
        <TableCell  >
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox checked={selected}
            onClick={onClick}
            size="small"
            data-testid={`checkbox-${row.id}`}
            />
            
        </TableCell>
        <TableCell >{row?.name}</TableCell>
        <TableCell><CheckCircleOutline color="disabled" sx={{ fontSize:'18px'}} /> {row.status || 'ready'}</TableCell>
        <TableCell>{row?.organism}</TableCell>
        {row && Object.values(row?.other_categories || {}).map((v: any) => {
          return <TableCell>{v}</TableCell>
        })}
      </TableRow>
      <TableRow>
        <TableCell sx={{ p:'5px' }} colSpan={5 + Object.keys(row?.other_categories || {}).length} >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" sx={{mt:1 }}>
              <TableHead>
                <TableRow sx={{ bgcolor:'surface-container-high' }}>
                  <TableCell>Sample ID</TableCell>
                  <TableCell>Seq-end</TableCell>
                  <TableCell>Sense</TableCell>
                </TableRow>
              </TableHead>
              <TableRow sx={{bgcolor:'surface-container-low' }}>
                <TableCell>{'S-' + row.sample_num}</TableCell>
                <TableCell>{exp?.sequencing_read_type}</TableCell>
                <TableCell>{exp?.sequencing_sense || 'Unstranded'}</TableCell>
              </TableRow>
            </Table>
              <Table size="small" sx={{mt:1}}>
                <TableHead>
                  <TableRow sx={{ bgcolor:'surface-container-high' }}>
                    <TableCell >  </TableCell>
                    <TableCell>File ID</TableCell>
                    <TableCell>File Name</TableCell>
                    <TableCell>Lane Number</TableCell>
                    <TableCell>Read Number</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{bgcolor:'surface-container-low' }} >
                  {row?.files?.map((fileEntry: any) => (
                    <TableRow key={fileEntry.id} >
                      <TableCell align="right" >  
                      {/* the download button below is disabled for now. uncomment to enable 
                         <CloudDownload sx={{ fontSize:'18px', color:'gray' }} /> */}
                      </TableCell>
                      <TableCell align="left">SM-{fileEntry.id.slice(0, 5)}</TableCell>
                      <TableCell>{fileEntry.name}</TableCell>
                      <TableCell align="center">{'00' + fileEntry?.lane_number ||<Button variant="text" startIcon={<ErrorOutline /> } endIcon={<ArrowRightAlt />} color="error" onClick={(e)=> setEditMeta(fileEntry)} ><Typography variant="label" size="medium - prominent" >Add Metadata</Typography></Button>  }</TableCell>
                      <TableCell align="center">{fileEntry?.read_number || <Button variant="text" startIcon={<ErrorOutline /> } endIcon={<ArrowRightAlt />} color="error" onClick={(e)=> setEditMeta(fileEntry)} ><Typography variant="label" size="medium - prominent" >Add Metadata</Typography></Button>}</TableCell>

                      <TableCell> <Edit sx={{ fontSize:'18px', cursor:'pointer'}} onClick={(e)=> setEditMeta(fileEntry)} /> </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableCell colSpan={8} ><Box sx={{mt:1, borderTop: '1px solid', borderColor:'outline-variant' }}/></TableCell>
     
              </Table>
          </Collapse>
        </TableCell>
      </TableRow>
      <Popup isOpen={editMeta ? true : false}> <EditMetaForm file={editMeta} expId={exp?.id} sId={row?.id} setEdit={setEditMeta} refetch={refetch} /> </Popup> 
    </>
  );
}
