import { Box, Button, Container, TextField, Typography } from "@mui/material"
import React, { useState } from "react"
import TempLogo from "../../components/TempLogo"
import { CardComponent, CardNumber, CardExpiry, CardCVV } from "@chargebee/chargebee-js-react-wrapper"
import "./index.css"
import LoadingButton from "@mui/lab/LoadingButton"
import CostSummary from "../CostSummary"
import { useAuth0 } from "@auth0/auth0-react"
import { getAppConfig } from "../../utils/config"
import { useCCForm } from "../../hooks/useCCForm"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert"
import logo from "../../assets/logo.png"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import CostSummaryInitial from "../CostSummaryInitial"

interface PayProps {
    plan?: Plan
    unselectPlan?: () => void
}

const inputStyles = {
    base: {
        color: "#49454F",
        fontSize: "16px",
        lineHeight: "40px"
    }
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const Pay: React.FC<PayProps> = ({ plan, unselectPlan }) => {
    const [code, setCode] = useState<string>("")
    const [codeValid, setCodeValid] = useState<boolean>(false)
    const [discountPercentage, setDiscountPercentage] = useState<number>(0)
    const { getAccessTokenSilently } = useAuth0()
    const { apiHost } = getAppConfig()
    const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: AlertColor }>({
        open: false,
        message: "",
        severity: "error"
    })
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    const needsCC = !codeValid || discountPercentage !== 100

    const handleSubmit = async (token?: string) => {
        try {
            const bodyObject = {
                ...(needsCC && { token_id: token }),
                item_price_id: plan?.id,
                quantity: 1,
                ...(code && codeValid && { coupon: code })
            }
            console.log("bodyObject: ", bodyObject)
            const response = await fetch(`${apiHost}/subscriptions/first-time-subscribe`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(bodyObject)
            })
            const responseData = await response.json()
            console.log(responseData)
            if (response.ok) {
                console.log("OK response from handleSubmit")
                console.log("responseData: ", responseData)
                if (responseData.message === "Successfully subscribed") {
                    window.location.href = `/?welcome=true`
                }
                return "Good"
            } else {
                console.log("Error response for handleSubmit")
                console.log("responseData: ", responseData)
                setSnackbar({
                    open: true,
                    message: "Invalid payment method. Please check your card information.",
                    severity: "error"
                })
                return "Error"
            }
        } catch (err) {
            console.log("error in invokePaymentProcess: ", err)
            setSnackbar({
                open: true,
                message: "Invalid payment method. Please check your card information.",
                severity: "error"
            })
            return "Error"
        }
    }
    const {
        handleFormChange,
        isComplete,
        isLoading,
        cardRef,
        formErrors,
        handleFormSubmit,
        name: { name, setName }
    } = useCCForm(handleSubmit, setSnackbar)

    if (!plan) return <div>Plan not found</div>

    return (
        <Container
            sx={{
                paddingTop: "38px",
                paddingBottom: "100px",
                "&.MuiContainer-root": { maxWidth: "1400px" }
            }}>
            <Button
                variant="outlined"
                startIcon={<ArrowBackIcon sx={{ color: "#323232" }} />}
                sx={{ width: "98px" }}
                onClick={unselectPlan}>
                Back
            </Button>
            <Box margin="27px auto 0 auto" width="fit-content">
                <img src={logo} alt="logo" width="200px" />
                <Typography variant="headline" size="large" sx={{ marginTop: "45px" }}>
                    {`Let's get your ${plan?.title} plan set up.`}
                </Typography>
                <Box
                    display="flex"
                    flexDirection={isMobile ? "column" : "row"}
                    marginTop="36px"
                    alignItems="flex-start"
                    gap="48px">
                    <CostSummaryInitial
                        plan={plan}
                        code={code}
                        setCode={setCode}
                        discountPercentage={discountPercentage}
                        setDiscountPercentage={setDiscountPercentage}
                        codeValid={codeValid}
                        setCodeValid={setCodeValid}
                    />
                    <Box
                        bgcolor="surface-container-lowest"
                        borderRadius="20px"
                        padding={isMobile ? "20px" : "27px 88px 50px 50px"}>
                        {!needsCC ? (
                            <Typography>No card payment needed.</Typography>
                        ) : (
                            <>
                                <Typography variant="headline" size="medium">
                                    Enter your secure
                                    <br />
                                    payment details
                                </Typography>
                                <CardComponent
                                    ref={cardRef}
                                    className="pay-outer"
                                    icon={true}
                                    onChange={handleFormChange}>
                                    {formErrors.error && (
                                        <Box>
                                            <Typography color="error" variant="body1">
                                                {formErrors.error}
                                            </Typography>
                                        </Box>
                                    )}

                                    <TextField
                                        variant="outlined"
                                        label="Name on Card"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "16px",
                                            width: isMobile ? "100%" : "404px",
                                            letterSpacing: "0.5px",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#E0DCEA"
                                            }
                                        }}
                                    />
                                    <Box display="flex" flexDirection="column">
                                        {formErrors.number && (
                                            <Typography color="error" variant="body1">
                                                {formErrors.number}
                                            </Typography>
                                        )}
                                        <CardNumber
                                            className={isMobile ? "pay-input-mobile number" : "pay-input number"}
                                            styles={inputStyles}
                                        />
                                    </Box>
                                    <Box display="flex" flexDirection="row" gap="24px" alignItems="flex-end">
                                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            {formErrors.expiry && (
                                                <Typography color="error" variant="body1">
                                                    {formErrors.expiry}
                                                </Typography>
                                            )}
                                            <CardExpiry className="pay-input expiry" styles={inputStyles} />
                                        </Box>
                                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            {formErrors.cvv && (
                                                <Typography color="error" variant="body1">
                                                    {formErrors.cvv}
                                                </Typography>
                                            )}
                                            <CardCVV className="pay-input cvv" styles={inputStyles} />
                                        </Box>
                                    </Box>
                                </CardComponent>
                            </>
                        )}
                        <LoadingButton
                            variant="contained"
                            disabled={!isComplete && needsCC}
                            loading={isLoading}
                            onClick={needsCC ? handleFormSubmit : () => handleSubmit()}
                            sx={{
                                width: isMobile ? "100%" : "437px",
                                marginLeft: isMobile ? "0" : "-9px",
                                marginTop: "44px"
                            }}>
                            {needsCC ? "Submit Payment" : "Enter Trovomics"}
                        </LoadingButton>
                        {needsCC && (
                            <Typography
                                variant="body"
                                size="small"
                                width={isMobile ? "100%" : "401px"}
                                sx={{ marginTop: "33px" }}
                                letterSpacing="0">
                                We take the security of your credit care information very seriously and are committed to
                                protecting your privacy and security
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ open: false, message: "", severity: "error" })}>
                <Alert
                    onClose={() => setSnackbar({ open: false, message: "", severity: "error" })}
                    severity={snackbar.severity}
                    sx={{ width: "100%" }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default Pay
