import { GeneralGraphProps } from "../../../types/GraphTypes";

export interface CompareProps extends GeneralGraphProps {
  geneList: string[];
  geneLists: string[];
}

export const compareConfig: CompareProps = {
  xTitle: "",
  title: "Comparison Plot",
  xTitlePos: 0,
  yTitlePos: 0.9,
  yTitle: "",
  fontSizeTitle: 20,
  fontSizeX: 12,
  fontSizeY: 12,
  width: 800,
  height: 500,
  geneList: [],
  geneLists: [],
  showGrid: false,
  setDefaults: true,
  mLeft: 0,
};
