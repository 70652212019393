import React, { useEffect, useState } from "react"
import { FormControl, InputLabel, MenuItem, Select, Box, Button } from "@mui/material"
import Popup from "../../../components/popup"
import { BeforeYouBegin } from "../../../components/modals/experiments/BeforeYouBegin"
import { RecallEmail } from "../../../components/modals/experiments/RecallEmail"
import { sequencing_details } from "../formOptions"
import { useNavigate } from "react-router-dom"
import { useTrovoConfig } from "../../../utils/config"

interface SequenceMap {
    Forward: string
    Reverse: string
    Unstranded: string
}

const Step2 = ({
    formData,
    setSequencingDetails
}: {
    formData: { sequencing_details: SequencingDetails }
    setSequencingDetails: (obj: SequencingDetails) => void
}) => {
    const navigate = useNavigate()
    const [isRecallTemplateModal, setRecallTemplateModal] = useState(true)
    const [pageState, setPageState] = useState<SequencingDetails>({
        analyzed_molecule: formData.sequencing_details.analyzed_molecule || "",
        rna_selection_method: formData.sequencing_details.rna_selection_method || "",
        sequencing_adapter: formData.sequencing_details.sequencing_adapter || "",
        sequencing_platform: formData.sequencing_details.sequencing_platform || "",
        platform_model: formData.sequencing_details.platform_model || "",
        sequencing_read_type: formData.sequencing_details.sequencing_read_type || "",
        sequencing_sense: formData.sequencing_details.sequencing_sense || "Unstranded",
        files_per_sample: formData.sequencing_details.files_per_sample || ""
    })
    const isSingleEnd = pageState.sequencing_read_type === "Single"
    const { user } = useTrovoConfig()
    const singEndSequenceMap: SequenceMap = {
        Forward: "F",
        Reverse: "R",
        Unstranded: ""
    }
    const pairedEndSequenceMap: SequenceMap = {
        Forward: "FR",
        Reverse: "RF",
        Unstranded: ""
    }
    const sequenceMap = {
        F: "Forward",
        FR: "Forward",
        R: "Reverse",
        RF: "Reverse",
        "": "Unstranded"
    }
    const a = [
        { label: "Forward", value: "F" },
        { label: "Reverse", value: "R" },
        { label: "Unstranded", value: "" }
    ]
    const b = [
        { label: "Forward", value: "FR" },
        { label: "Reverse", value: "RF" },
        { label: "Unstranded", value: "" }
    ]

    useEffect(() => {
        console.log("Step2: ", formData)
    }, [])

    useEffect(() => {
        console.log("step2 pageState", pageState)
    }, [pageState])

    const complete = Boolean(
        pageState.analyzed_molecule &&
            pageState.rna_selection_method &&
            pageState.sequencing_adapter &&
            pageState.sequencing_platform &&
            pageState.platform_model &&
            pageState.sequencing_read_type &&
            pageState.sequencing_sense &&
            pageState.files_per_sample
    )

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        console.log("name: ", name, "value: ", value)
        setPageState({ ...pageState, [name]: value })
    }

    const handleSubmit = () => {
        const isSingeEndReadType = pageState.sequencing_read_type === "Single"
        const selectedSequencingSense = pageState.sequencing_sense as keyof SequenceMap // Type assertion here

        setSequencingDetails({
            analyzed_molecule: pageState.analyzed_molecule,
            rna_selection_method: pageState.rna_selection_method,
            sequencing_adapter: pageState.sequencing_adapter,
            sequencing_platform: pageState.sequencing_platform,
            platform_model: pageState.platform_model,
            sequencing_read_type: pageState.sequencing_read_type,
            sequencing_sense: isSingeEndReadType
                ? singEndSequenceMap[selectedSequencingSense]
                : pairedEndSequenceMap[selectedSequencingSense],
            files_per_sample: pageState.files_per_sample
        })
    }

    return (
        <Box component={"form"} sx={{ "& label": { width: "60ch" }, display: "flex", flexDirection: "column", gap: 4 }}>
            {sequencing_details.map(which => {
                const { name, readable, select } = which
                let is = pageState[name]
                if (name === "sequencing_sense") {
                    is = sequenceMap[is as keyof typeof sequenceMap] ?? pageState[name]
                }

                return (
                    <FormControl key={name}>
                        <InputLabel>{readable}</InputLabel>
                        <Select
                            name={name}
                            value={is}
                            onChange={handleInputChange}
                            label={readable}
                            disabled={
                                (name === "files_per_sample" && pageState["sequencing_read_type"] === "") ||
                                user.cb_item_price_id?.includes("demo")
                            }>
                            {name !== "files_per_sample" &&
                                select.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {name === "sequencing_read_type"
                                            ? option === "singled"
                                                ? "Single-End"
                                                : "Paired-End"
                                            : option}
                                    </MenuItem>
                                ))}
                            {name === "files_per_sample" &&
                                pageState["sequencing_read_type"] === "singled" &&
                                ["1", "2", "3", "4"].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            {name === "files_per_sample" &&
                                pageState["sequencing_read_type"] === "paired" &&
                                ["2", "4", "6", "8"].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                )
            })}
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!complete && !user.cb_item_price_id?.includes("demo")}
                fullWidth>
                Save and Continue
            </Button>
            <Button variant="text" color="primary" onClick={() => navigate(`/`)} fullWidth>
                Save Progress and Exit
            </Button>
            <Popup
                isOpen={isRecallTemplateModal}
                onClose={() => setRecallTemplateModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <RecallEmail onClose={() => setRecallTemplateModal(false)} />
            </Popup>
        </Box>
    )
}

export default Step2
