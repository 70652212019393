import React from "react"
import { Typography, Button } from "@mui/material"
import { CheckCircle } from '@mui/icons-material'
import { styled } from "@mui/material/styles"
import {useNavigate} from "react-router-dom"

export const ContainerStyled = styled("div")({
    padding: "40px"
})

export const CheckCircleStyled = styled(CheckCircle)({
    width: 66,
    height: 66,
    marginBottom: 30,
    color: 'black'
})

export const TitleStyled = styled(Typography)({
    marginBottom: "30px",
    fontSize: "24px",
    fontWeight: 600
})

export const TextStyled = styled(Typography)({
    fontSize: "14px",
    marginBottom: "20px"
})

export const ButtonStyled = styled(Button)({
    width: "100%",
    marginTop: "10px",
    backgroundColor: "black"
})

interface AnalysisStartedProps {
    onClose?: () => void
    experimentId: string | undefined
}

export const AnalysisStarted: React.FC<AnalysisStartedProps> = ({ experimentId, onClose }) => {
    const navigate = useNavigate()

    const onHandleClose = () => {
        if (onClose) {
            onClose()
        }
        navigate('/')
    }

    return (
        <ContainerStyled>
            <CheckCircleStyled />
            <TitleStyled>
                Analysis has started
            </TitleStyled>
            <TextStyled>
                This analysis includes samples that have not yet been analyzed and may take up to 24 hours to complete. You will <br />
                be notified via email when your analysis is complete. You can also refer to your experiment dashboard to check <br />
                progress.
            </TextStyled>
            <TextStyled sx={{ fontWeight: 600 }}>
                Note: Once initial analysis of your samples is complete, additional analysis may be processed faster.
            </TextStyled>

            <ButtonStyled onClick={() => navigate(`/experiment/${experimentId}`)} variant="contained">
                Return to Experiment Details
            </ButtonStyled>
            <ButtonStyled onClick={onHandleClose} variant="outlined" sx={{ background: "white" }}>
                Go to the main Dashboard
            </ButtonStyled>
        </ContainerStyled>
    );
}
