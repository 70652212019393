import { Box, Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Row from './SampleRow'
import { Cancel, WarningAmberOutlined } from "@mui/icons-material";

import { useAuth0 } from "@auth0/auth0-react";
import { getAppConfig } from "../../../../utils/config";
import Popup from "../../../../components/popup";

const SamplesTable = ({
  setSuccess,
  data,
  errors,
  globalValues,
  fetchSamples,
  experiment,
  setDeleteCallback,
  editSample,
  handleSelectRow,
  selectedSamples,
  setSelectedSamples
}: any) => {
  const { apiHost } = getAppConfig();
  const { getAccessTokenSilently } = useAuth0();
  const [deletePopup, setDeletePopup] = useState(false)
  const [sortedRows, setSortedRows] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.checked) {
      const newSelectedSamples = new Set(data.map((sample: any) => sample.id));
      setSelectedSamples(newSelectedSamples);
    } else {
      setSelectedSamples(new Set());
    }
    setSelectAll(!selectAll)
  };

  const triggerDelete = () => {
    if (selectedSamples.size > 0) {
      setDeletePopup(true)
    }
  }

  const handleDelete = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const deletePromises = Array.from(selectedSamples).map(s => {
        return fetch(`${apiHost}/experiment/${experiment.id}/sample/${s}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }
        });
      });
      const responses = await Promise.all(deletePromises);
      setSelectedSamples(new Set())
      setDeletePopup(false)
      setSuccess(true)
      fetchSamples()
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };

  useEffect(()=> {
    setDeleteCallback(() => triggerDelete);
  }, [selectedSamples])

  useEffect(() => {
    const errArray = errors ? Object?.values(errors)?.flat() : []
    if (errArray?.length) {
      const sortRows = data?.sort((a: any, b: any) => {
        const bError = errArray?.includes(b.id)
        const aError = errArray?.includes(a.id)
        if (aError && !bError) return -1
        if (!aError && bError) return 1
        return 0
      })
      setSortedRows(sortRows)
    } else {
      setSortedRows(data ? data?.sort((a:any, b:any) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0) : [])
    }
  }, [errors, data])

  return (
    <Box>
      <TableContainer sx={{
        border: "1px solid",
        borderColor: "outline-variant",
        borderRadius: "5px",
        mt: 3,
        paddingBottom: "10px",
        "& input": {
          height: "23px",
          fontSize: "15px",
          padding: "4px",
        },

      }}>
        <Table aria-label="collapsible table" sx={{ 'th, td': { border: 0 } }} >
          <TableHead sx={{ 'th': { bgcolor: 'surface-container-high', height: '25px', py: 0 } }} >
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" >
                <Checkbox
                  size="small"
                  onChange={(e)=>handleSelectAllRows(e)}
                  checked={selectAll}
                  indeterminate={selectedSamples.size > 0 && selectedSamples.size < data.length}
                  sx={{ padding:1}}
                />
              </TableCell>
              <TableCell >Sample Name</TableCell>
              {data && data?.length ? Object.keys(globalValues)?.map((k: any, i:number) => {
                return <TableCell key={i}>{k}</TableCell>
              }) : <TableCell></TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows?.length > 0 ? sortedRows?.map((row: any, i: number) => (
              <>
                <TableRow sx={{ my: 1, borderTop: '1px solid', borderColor: '#e0dcea6e' }} />
                <Row
                  globalValues={globalValues}
                  exp={experiment}
                  key={row?.id}
                  row={row}
                  errors={errors}
                  fetchSamples={fetchSamples}
                  selected={selectedSamples.has(row?.id)}
                  selectRow={handleSelectRow}
                  editSample={editSample}
                  setSuccess={setSuccess} />
              </>
            )) :  <TableRow > <TableCell /><TableCell /> <TableCell sx={{ textAlign: 'center' }}>No results to display</TableCell></TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {deletePopup && <Popup isOpen={true} onClose={() => { }}>
        <Box py={5} px='8%' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', cursor: 'pointer', gap: 3 }} >
          <WarningAmberOutlined sx={{ fontSize: '60px', color: '#BA1A1A', my: 1, alignSelf: 'center' }} />
          <Typography variant="title" size="large" > Are you sure you want to delete these samples? </Typography>
          <Typography variant="body" size="medium">This action cannot be undone and will permanently delete these samples from your experiment. Deleting these files will not affect any analyses you've already run, but these samples cannot be included in future analyses. </Typography>
          <Typography variant="body" size="medium"> Do you still want to delete the selected samples?</Typography>
          <Button variant="contained" onClick={handleDelete} > Delete ({selectedSamples.size}) sample{`${selectedSamples.size > 1 ? 's' : ''}`} </Button>
          <Button endIcon={<Cancel />} onClick={() => setDeletePopup(false)} >Nevermind, cancel</Button>
        </Box>
      </Popup>}
    </Box>
  );
}

export default SamplesTable