import React from "react"
import { Box, Button, Divider, Typography } from "@mui/material"
import { FileDownload } from "@mui/icons-material"
import SideWidget from "../SideWidget"
import { useOutletContext } from "react-router-dom"
import { getAppConfig } from "../../../utils/config"
import { useAuth0 } from "@auth0/auth0-react"

interface InvoicesProps {}

const Invoices: React.FC<InvoicesProps> = () => {
    const { accountState } = useOutletContext<{
        accountState: AccountState
        setAccountState: AccountState
        setShowBanner: (show: boolean) => void
    }>()
    const { getAccessTokenSilently } = useAuth0()
    const { apiHost } = getAppConfig()

    const handleInvoiceDownload = async (invoiceId: string | number) => {
        console.log("Downloading invoice with id: ", invoiceId)
        const response = await fetch(`${apiHost}/subscriptions/invoice/pdf/${invoiceId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${await getAccessTokenSilently()}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        // the invoice link is stored in the response object's messsage property
        const responseData = await response.json()
        if (response.ok) {
            console.log("OK response from handleInvoiceDownload")
            console.log("responseData: ", responseData)
            window.open(responseData.message, "_blank")
        } else {
            console.log("Error response from handleInvoiceDownload")
            console.log("responseData: ", responseData)
        }
    }
    return (
        <Box marginTop="53px" display="flex" flexDirection="row" gap="51px" paddingLeft="7px" alignItems="flex-start">
            <Box flex="1">
                <Typography variant="title" size="medium" marginLeft="10px">
                    Past invoices
                </Typography>
                {accountState.invoices.length === 0 && (
                    <Typography variant="label" size="large" marginLeft="10px" color="on-surface-variant">
                        No invoices available
                    </Typography>
                )}
                {accountState.invoices.map((invoice: any) => (
                    <React.Fragment key={invoice.year}>
                        <Box marginTop="31px">
                            <Typography
                                variant="label"
                                size="large-prominent"
                                marginLeft="10px"
                                color="on-surface-variant">
                                {invoice.year}
                            </Typography>
                            <Divider sx={{ marginTop: "26px", borderColor: "outline-variant" }} />
                            {invoice.invoices.map((i: any) => (
                                <React.Fragment key={i.id}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap="24px"
                                        height="48px"
                                        alignItems="center"
                                        padding="0 10px">
                                        <Box width="105px">
                                            <Typography
                                                variant="label"
                                                size="large-prominent"
                                                color="on-surface-variant">
                                                {i.date.month}
                                            </Typography>
                                        </Box>
                                        <Box width="76px">
                                            <Typography variant="label" size="large" color="on-surface-variant">
                                                {i.paymentMethod}
                                            </Typography>
                                        </Box>
                                        <Box width="121px" flex="1">
                                            <Typography variant="label" size="large" color="on-surface-variant">
                                                ${(i.total / 100).toFixed(2)}
                                            </Typography>
                                        </Box>
                                        <Button
                                            onClick={() => handleInvoiceDownload(i.id)}
                                            startIcon={<FileDownload sx={{ color: "primary.main" }} />}>
                                            <Typography variant="label" size="large" color="primary.main">
                                                View/download
                                            </Typography>
                                        </Button>
                                    </Box>
                                    <Divider sx={{ borderColor: "outline-variant" }} />
                                </React.Fragment>
                            ))}
                        </Box>
                    </React.Fragment>
                ))}
            </Box>
            <SideWidget
                subscription={accountState.subscription}
                paymentSource={accountState.paymentSource}
                plan={accountState.plans.find(plan => plan.current === true)}
            />
        </Box>
    )
}

export default Invoices
