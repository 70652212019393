import { GOProps } from "./index";
import { VisData } from "../../../utils/database";

export function getGODataProps(data: VisData) {
  const v = Object.values(data.geneOntologyOutput.data);
  if (!v[0]) {
    return { ontologyList: [] };
  }

  return { ontologyList: Object.keys(v[0]) };
}

export function mapGOCompare(data: any) {
  const map: { [k: string]: any } = {};
  data.forEach((d: any) => {
    if (!map[d.comparison]) {
      map[d.comparison] = {};
    }
    if (!map[d.comparison][d.ontology]) {
      map[d.comparison][d.ontology] = [];
    }
    map[d.comparison][d.ontology].push(d);
  });
  return map;
}
export function geneListsToMap(data: { [k: string]: any }) {
  const gl = data[0].gene_lists;
  const map: { [k: string]: any } = {};
  Object.keys(gl).forEach((k: any) => {
    const d = gl[k];
    if (!Array.isArray(d.List)) {
      return;
    }
    if (!map[k]) {
      map[k] = d.List.reduce((a: { [k: string]: string }, c: string) => {
        a[c] = c;
        return a;
      }, {});
    }
  });
  data[0]["gene_list_map"] = map;
  return data[0];
}

export function getGOFigure({ data, compare, ontology }: any) {
  const ontologyData = data.geneOntologyOutput.data;
  const graphMap: { [key: string]: any } = {};
  const sorted = [...ontology].sort();
  compare.forEach((c: string) => {
    if (ontologyData[c]) {
      sorted.forEach((o: string) => {
        if (!ontologyData[c][o]) {
          return;
        }
        const set = ontologyData[c][o];
        if (!graphMap[o]) {
          graphMap[o] = [];
        }
        graphMap[o].push(...set);
      });
    }
  });
  let maxYTitle = 0;
  const dataSets = Object.values(graphMap)
    .map((d: any) => {
      return d.reduce((a: any, c: any) => {
        if (!a[c.description]) {
          a[c.description] = 0;
        }
        a[c.description] += parseFloat(c.count);
        return a;
      }, {});
    })
    .map((d: any, i) => {
      const y = Object.keys(d);
      y.forEach((i) => {
        const u = d[i];
        const curr = i.length;
        if (curr > maxYTitle) {
          console.log(i, u);
          maxYTitle = curr;
        }
      });
      return {
        x: Object.values(d),
        y,
        type: "bar",
        orientation: "h",
        name: Object.keys(graphMap)[i],
        legendgroup: Object.keys(graphMap)[i],
      };
    });
  console.log(maxYTitle);
  return {
    traces: dataSets,
    layout: {
      margin: { l: maxYTitle * 6 },
      bargroupgap: 200,
    },
  };
}
