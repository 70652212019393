import React, {useEffect, useState} from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Select,
    MenuItem,
    Button,
    Box,
    Typography,
} from "@mui/material";
import { Cancel, Check, CheckCircle, CheckCircleOutline } from "@mui/icons-material";
import FileUploadBox from "./FileUploadBox";
import { useAuth0 } from "@auth0/auth0-react";
import { getAppConfig } from "../../utils/config";
import { GradientCircularProgress } from "../ExperimentCreation/SamplesProgModal";
import Popup from "../../components/popup";

type SampleData = {
    number_of_files?: any;
    id?: string;
    name: string;
    other_categories: any;
    files?: Array<FileData>;
};

type FileData = {
    id: string;
    name: string;
    status?: string;
    lane_number?: number;
    read_number?: number;
};

type AddSampleProps = {
    isDuplicationStarted?: boolean;
    data: SampleData[];
    sampleSource?: SampleData;
    exp: any;
    setAdding: any;
    onRemoveSample?: (sampleId: string | undefined) => void;
    reFetchSamples?: any
};

export default function AddNewSample({
    data,
    exp,
    setAdding,
    isDuplicationStarted,
    sampleSource,
    onRemoveSample,
    reFetchSamples
}: AddSampleProps) {
    const { getAccessTokenSilently } = useAuth0();
    const { apiHost } = getAppConfig();
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [uploading, setUploading] = useState(false);
    const [finished, setFinished] = useState<any>([])
    const [newSample, setNewSample] = useState<SampleData | any>(sampleSource);
    const [textFieldValue, setTextFieldValue] = useState("");
    const DEFAULT_SAMPLE_NAME = "Sample Name";
    const duplicateSampleName = sampleSource ? `${sampleSource.name} Duplicate` : "";
    const numFiles =
        exp?.files_per_sample ||
        data?.[0].number_of_files ||
        data?.[0].files?.length;

    // Calculates the values for the dropdown menus
    const calculateValues = (data: SampleData[], key: string): string[] => {
        const allValues = new Set<string>();
        data.forEach((d) => {
            const value = d.other_categories[key];
            if (value && !allValues.has(value)) {
                allValues.add(value);
            }
        });
        return Array.from(allValues);
    };

    const handleInputChange = (key: string, value: string) => {
        setNewSample({ ...newSample, [key]: value });
    };

    // Check if all inputs have values to enable the save button
    const allInputsHaveValues =
        newSample && newSample.name !== "" && newSample.other_categories &&
        Object.values(newSample?.other_categories).every((value) => value !== "") &&
        textFieldValue !== DEFAULT_SAMPLE_NAME && textFieldValue !== duplicateSampleName;

    const handleClose = () => {
        setNewSample({})
        setSelectedFiles([])
        setAdding(false)
        if (sampleSource && onRemoveSample) {
            onRemoveSample(sampleSource.id);
        }
        reFetchSamples && reFetchSamples()
    }

    // Functions Related to Saving & Uploading Sample & Files ------ >
    const getPresignedUrl = async (
        sampleId: string,
        fileId: string,
        filename: string
    ) => {
        return fetch(
            `${apiHost}/experiment/${exp.id}/sample/${sampleId}/file/${fileId}/get-presigned-url`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                },
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                return { url: data.url, filename: filename };
            });
    };

    const uploadFile = async (url: string, file: any) => {
        return fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "text/plain",
            },
            body: file,
        })
            .then((data) => {
                setFinished((state: any) => [file.name, ...state])
            })
            .catch((err) => {
                console.log("error uploading file: ", err);
            });
    };

    const addNewSample = async () => {
        setUploading(true);
        const fileObjectsToInsert = selectedFiles.map((obj: any) => {
            const filename = obj.name;
            const filenameSplit = filename.split("_");
            const laneNumber =
                filenameSplit.length >= 5
                    ? filenameSplit[filenameSplit.length - 3][3]
                    : "1";
            const readNumber =
                filenameSplit.length >= 5
                    ? filenameSplit[filenameSplit.length - 2][1]
                    : filenameSplit.length === 2
                        ? filenameSplit[1][0]
                        : "1";
            return {
                name: filename,
                original_size: obj.size,
                strandedness_seq_sense: exp.sequencing_sense || '',
                strandedness_seq_end: exp.sequencing_read_type && exp.sequencing_read_type.toLowerCase(),
                interleaved: false,
                status: "unknown",
                lane_number: laneNumber,
                read_number: readNumber,
            };
        });
        const sampleRecord = {
            name: newSample.name,
            organism: exp.organism,
            analyzed_molecule: exp.analyzed_molecule,
            sequencing_instrument_platform: exp.sequencing_platform,
            sequencing_instrument_model: exp.platform_model,
            experiment_id: exp.id,
            other_categories: newSample.other_categories,
            files: fileObjectsToInsert,
        };

        const response = await fetch(
            `${apiHost}/experiment/${exp.id}/sample/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                },
                body: JSON.stringify(sampleRecord),
            }
        );
        const sampleRec = await response.json();
        const uploadPromises = sampleRec.files.map(async (f: { name: any; id: string; }) => {
            const file = selectedFiles.find((s: { name: any; }) => s.name === f.name);
            if (file) {
                const fileUrl = await getPresignedUrl(sampleRec.id, f.id, f.name);
                return uploadFile(fileUrl.url, file);
            }
            return Promise.resolve();
        });
        await Promise.all(uploadPromises);

        setUploading(false);
        reFetchSamples && reFetchSamples()
    };

    useEffect(() => {
        if (!isDuplicationStarted) {
            setTextFieldValue("Sample Name");
        } else if (sampleSource) {
            setTextFieldValue(`${sampleSource.name} Duplicate`);
        }
    }, [sampleSource, isDuplicationStarted]);

    useEffect(() => {
        setNewSample(sampleSource)
    }, [sampleSource])

    useEffect(() => {
        setTextFieldValue(isDuplicationStarted ? duplicateSampleName : DEFAULT_SAMPLE_NAME);
    }, [isDuplicationStarted, duplicateSampleName]);

    return (
        <TableContainer
            sx={{
                border: "0px solid",
                borderColor: "outline",
                borderRadius: "8px",
                mb: 2,
            }}
        >
            <Table>
                <TableHead
                    sx={{
                        bgcolor: "surface-container-high",
                        px: 4,
                        th: { py: 1, textAlign: "center", fontWeight: "500" },
                    }}
                >
                    <TableRow>
                        <TableCell>Sample Name</TableCell>
                        {data?.length > 0 &&
                            Object.keys(data[0].other_categories).map((k) => (
                                <TableCell key={k}>{k}</TableCell>
                            ))}
                        <TableCell />
                    </TableRow>
                </TableHead>
                {!uploading ? (
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    value={textFieldValue}
                                    onChange={(e) => {
                                        setTextFieldValue(e.target.value);
                                        handleInputChange("name", e.target.value);
                                    }}
                                    fullWidth
                                    inputProps={{
                                        'data-testid': 'sample-name',
                                    }}
                                />
                            </TableCell>
                            {data?.length > 0 && sampleSource && sampleSource.other_categories &&
                                Object.keys(sampleSource?.other_categories).map((k) => {
                                    const precalculatedValue = Object.keys(newSample).length
                                        ? newSample.other_categories[k]
                                        : ""

                                    return (
                                        <TableCell key={k}>
                                            <Select
                                                name={k}
                                                fullWidth
                                                value={precalculatedValue}
                                                onChange={(e) =>
                                                    setNewSample({
                                                        ...newSample,
                                                        other_categories: {
                                                            ...newSample?.other_categories,
                                                            [k]: e.target.value,
                                                        },
                                                    })
                                                }
                                            >
                                                {calculateValues(data, k)?.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </TableCell>
                                    );
                                })}
                            <TableCell align="right">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ my:0,}}
                                    startIcon={<Check />}
                                    onClick={() => {
                                        addNewSample();
                                    }}
                                    disabled={
                                        !allInputsHaveValues || selectedFiles.length < numFiles
                                    }
                                > Save & Upload Sample
                                </Button>
                                <br />
                                {!allInputsHaveValues && <Typography variant="label" size="medium - prominent" color='error' >*Please Provide a Name for this sample</Typography>}
                                <br />
                                {selectedFiles.length < numFiles && <Typography variant="label" size="medium - prominent" color='error'> *Please add {numFiles - selectedFiles?.length} file(s) to this sample </Typography>}
                                <br />
                                <Button startIcon={<Cancel />} variant="outlined" sx={{ mt:'4px', px: 2, height: '30px' }} onClick={handleClose} >Cancel</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    // File Upload In-Progress Box ---->
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">{newSample.name}</TableCell>
                            {Object.keys(newSample).length && Object.values(newSample?.other_categories).map((c: any) => (
                                <TableCell align="center">{c}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ p: 5, bgcolor: 'surface-container-low' }} colSpan={6}>
                                {selectedFiles?.map((f: any) => <Typography variant="body" size="medium">{f.name} {finished.includes(f.name) ? <CheckCircle /> : <GradientCircularProgress gradientId="gradient-add-files" />} </Typography>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
            {!uploading && (
                <FileUploadBox
                    maxFiles={numFiles}
                    selectedFiles={selectedFiles}
                    onFilesSelected={setSelectedFiles}
                />
            )}
            {selectedFiles.length > 0 && finished.length == selectedFiles.length && <Popup isOpen={true} onClose={() => handleClose()}>
                <Box p={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }} >
                    <Typography variant="title" size="large" ><CheckCircleOutline sx={{ fontSize: '47px',  mr:1 }} /> Your Sample Has Been Created and File Upload is Complete</Typography>
                    <Button startIcon={<Cancel />} variant="contained" sx={{ mt: 4, px: 4, }} onClick={handleClose} >Close</Button>
                </Box>
            </Popup>}
        </TableContainer>
    );
}
