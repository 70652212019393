import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import TempLogo from "../../components/TempLogo"
import Container from "@mui/material/Container"
import { Box, Button, Typography } from "@mui/material"
import { getAppConfig } from "../../utils/config"
import { useAuth0 } from "@auth0/auth0-react"
import LoadingButton from "@mui/lab/LoadingButton"
import logo from "../../assets/logo.png"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

interface VerifyProps {}

const Verify: React.FC<VerifyProps> = () => {
    // ensures that the url is always /verify
    // const navigate = useNavigate()
    // useEffect(() => {
    //     navigate("/verify", { replace: true })
    // }, [navigate])
    const { apiHost } = getAppConfig()
    const { getAccessTokenSilently } = useAuth0()
    const [loading, setLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    const handleResendEmail = async () => {
        setLoading(true)
        setShowSuccess(false)
        try {
            const response = await fetch(`${apiHost}/resend-verification-email`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({})
            })
            const data = await response.json()
            console.log(data)
            if (data.message === "Email sent") {
                setShowSuccess(true)
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
            return "Error"
        }
    }

    return (
        <Container sx={{ paddingTop: "100px", width: isMobile ? "100%" : "1440px", height: "100vh" }}>
            <img src={logo} alt="logo" width="200px" />
            <Typography variant="headline" size="large" sx={{ marginLeft: "6px", marginTop: "60px" }}>
                Please check your email to verify your account.
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center" marginTop="20px" gap="36px">
                <LoadingButton
                    variant="outlined"
                    onClick={handleResendEmail}
                    loading={loading}
                    sx={{
                        width: "133px",
                        marginLeft: "-4px"
                    }}>
                    Resend email
                </LoadingButton>
                {showSuccess && (
                    <Typography variant="body" size="medium">
                        A new verification email has been send to your email on file.
                    </Typography>
                )}
            </Box>
        </Container>
    )
}

export default Verify
