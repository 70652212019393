import React, { lazy, useState, useMemo, useEffect } from "react"
import { Routes, Route } from "react-router-dom"
import { useTrovoConfig } from "./utils/config"
import { LoginSignUp } from "./views/loginSignUp"
import "./App.css"
import { Nav } from "./components/Nav"
import { Experiment } from "./views/ExperimentDetails/Experiment"
import { Visualizer } from "./views/Visualizer"
import AccountDetails from "./views/AccountDetails"
import Verify from "./views/Verify"
import VerifyThanks from "./views/VerifyThanks"
import Invoices from "./views/AccountDetails/Invoices"
import PlanInformation from "./views/AccountDetails/PlanInformation"
import Billing from "./views/AccountDetails/Billing"
import CssBaseline from "@mui/material/CssBaseline"
import { Box, createTheme, ThemeProvider } from "@mui/material"
import { themeMaker } from "./theme"
import { ColorModeContext } from "./ColorModeContext"
import UpdatePayment from "./views/AccountDetails/Billing/UpdatePayment"
import PlansAndPay from "./views/PlansAndPay/Index"
import CreateExperiment from "./views/ExperimentCreation/CreateExperiment"
import CreateAnalysis from "./views/AnalysisCreation/CreateAnalysis"
import { Footer } from "./components/Footer"
import styled from "@emotion/styled"
import ManageSamples from "./views/ManageSamples/ManageSamples"

const ContainerStyled = styled("div")({
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    position: "relative",
    minHeight: "100vh",
    paddingBottom: 96
})

const Dashboard = lazy(() => import("./views/Dashboard/Dashboard"))

window.Chargebee.init({
    site: (window as any).CHARGEBEE_SITE || "rajanthealth-test",
    publishableKey: (window as any).CHARGEBEE_KEY || "test_XUhcdtFRIXvcuBe79PBxY4uyUwgAnQcdfmg"
})

function App() {
    const { user, loginWithRedirect, refreshUser, logout, isAuthenticated } = useTrovoConfig()
    const [mode, setMode] = useState<"light" | "dark">("light")
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode(prevMode => (prevMode === "light" ? "dark" : "light"))
            }
        }),
        []
    )
    const theme = useMemo(() => createTheme(themeMaker(mode)), [mode])

    useEffect(() => {
        console.log("process.env", process.env.NODE_ENV)
    }, [])
    useEffect(() => {
        if (process.env.NODE_ENV === 'development') (window as any).testUserData = user?.rhi_user_id 
    }, [user])

    console.log("window.AUTH0_TENANT", (window as any).AUTH0_TENANT)
    console.log("window.AUTH0_CLIENT_ID", (window as any).AUTH0_CLIENT_ID)
    console.log("window.AUTH0_AUDIENCE", (window as any).AUTH0_AUDIENCE)
    console.log("window.BFF_URL", (window as any).BFF_URL)
    console.log("window.CHARGEBEE_KEY", (window as any).CHARGEBEE_KEY)
    console.log("window.CHARGEBEE_SITE", (window as any).CHARGEBEE_SITE)

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes>
                    <Route
                        path="/login"
                        element={<LoginSignUp login={() => loginWithRedirect()} actionName={"Get Started"} />}
                    />
                    <Route
                        path="/sign-up"
                        element={<LoginSignUp login={() => loginWithRedirect()} actionName={"Sign up!!"} />}
                    />
                </Routes>
                {!isAuthenticated || !user ? (
                    false
                ) : (
                    <>
                        <ContainerStyled>
                            <Nav
                                user={user}
                                refreshUser={() => refreshUser()}
                                logout={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                            />
                            {user.email_verified === false ? (
                                <Box bgcolor="surface-container-low">
                                    <Routes>
                                        <Route path="*" element={<Verify />} />
                                    </Routes>
                                </Box>
                            ) : !user.subscription_id &&
                              user.cb_subscription_status !== "active" &&
                              user.cb_subscription_status !== "non_renewing" ? (
                                <Box bgcolor="surface-container-low">
                                    <Routes>
                                        <Route path="*" element={<VerifyThanks />} />
                                        <Route path="/plans" element={<PlansAndPay />} />
                                    </Routes>
                                </Box>
                            ) : (
                                <Box bgcolor="surface-container-low">
                                    <Routes>
                                        <Route path="/" element={<Dashboard />}>
                                            <Route path="" element={<Dashboard />} />
                                        </Route>
                                        <Route path="experiment/draft/" element={<CreateExperiment />} />
                                        <Route path="experiment/draft/:id" element={<CreateExperiment />} />
                                        <Route path="experiment/:id" element={<Experiment />} />
                                        <Route path="experiment/:id/analysis/draft/" element={<CreateAnalysis />} />
                                        <Route
                                            path="experiment/:id/analysis/draft/:analysisId"
                                            element={<CreateAnalysis />}
                                        />
                                        <Route path="samples/:id" element={<ManageSamples />} />
                                        <Route path="vis/:id" element={<Visualizer />} />
                                        {/* <Route path="/pay" element={<Pay />} /> */}
                                        <Route path="/account" element={<AccountDetails />}>
                                            <Route path="plan-info" element={<PlanInformation />} />
                                            <Route path="billing">
                                                <Route index element={<Billing />} />
                                                {/* <Route path="edit-payment" element={<UpdatePayment />} /> */}
                                            </Route>
                                            <Route path="invoices" element={<Invoices />} />
                                        </Route>
                                        <Route path="/account/billing/edit-payment" element={<UpdatePayment />} />
                                        <Route path="*" element={<Dashboard />} />
                                    </Routes>{" "}
                                </Box>
                            )}
                            <Footer />
                        </ContainerStyled>
                    </>
                )}
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

export default App
