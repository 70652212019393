import React from "react"
import { Typography, Box, Grid, IconButton, styled, Button } from "@mui/material"
import {
    CheckCircleOutlineOutlined,
    CircleOutlined,
    FileDownload,
    FileUpload,
    HighlightOff,
    Summarize,
    TextSnippet,
} from "@mui/icons-material"


const SampleBulkUpload = (props: any) => {
    const { pageState, handleDownloadClick, handleSelectFile, handleRemoveFile, handleCSVUpload, csvRef, handleFASTQUpload, fastqRef } = props;

    return (
        <Box>
            <Typography variant="headline" size="small">
                {!pageState?.csv.file?.name ? (
                    <CircleOutlined
                        sx={{
                            fontSize: "24px",
                            color: "primary-container",
                            marginRight: "7px"
                        }}
                    />
                ) : (
                    <CheckCircleOutlineOutlined
                        sx={{
                            fontSize: "24px",
                            color: "primary.main",
                            marginRight: "7px"
                        }}
                    />
                )}
                1. Format Your Experimental Design
            </Typography>
            <Typography variant="body" size="medium" px={4} pb={1} mt={2}>
                Prepare your sample information using the spreadsheet template below.
            </Typography>
            <Typography variant="body" size="medium" px={4} pb={1} mt={0.5}>
                <b>Important</b>: Please ensure the template is formatted properly according to the following
                instructions.
            </Typography>
            <Typography variant="body" size="medium" px={4} mt={0.5} marginBottom="20px">
                <ol style={{ paddingLeft: "1.5rem", lineHeight: "1.6" }}> 
                    <li>
                        The template file must contain the following two columns:
                        <ul style={{ paddingLeft: "1.5rem" }}>
                            <li>SampleName (uppercase 'S' and 'N')</li>
                            <li>Filename (uppercase 'F')</li>
                        </ul>
                    </li>
                    <li>
                        Add additional columns for the sample metadata you wish to include (e.g., "Disease", "Age",
                        "Sex", etc).
                    </li>
                    <li>
                        Add the names of the files in the "Filename" column exactly as you received them from the
                        sequencing facility.
                    </li>
                </ol>
            </Typography>
            <Box
                onClick={handleDownloadClick}
                border="1px solid"
                borderColor="outline-variant"
                display="flex"
                borderRadius="12px"
                margin="2% 0"
                padding="2%">
                <IconButton>
                    <Summarize
                        sx={{
                            color: "on-surface",
                            fontSize: "60px",
                            marginRight: "20px",
                            paddingRight: "15px",
                            borderRight: "1px solid",
                            borderColor: "outline-variant"
                        }}
                    />
                </IconButton>
                <Grid sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Typography variant="title" size="medium">
                        Download our template — use this file to correctly format your experimental design.
                    </Typography>
                    <Grid sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
                        <FileDownload sx={{ color: "on-surface", fontSize: "37px" }} />
                        <span>
                            <Typography variant="body2">Download our experimental design template</Typography>
                            <Typography variant="body2">CSV 10 KB</Typography>
                        </span>
                    </Grid>
                </Grid>
            </Box>
            {/**
             *
             *
             *
             *  CSV UPLOAD FORM
             *
             *
             *
             *
             */}
            <Box onClick={() => handleSelectFile("csv")}>
                {!pageState?.csv.file?.name ? (
                    <Box
                        bgcolor="surface-container-low"
                        border="1px dashed"
                        borderColor="outline-variant"
                        justifyContent="center"
                        textAlign="center"
                        borderRadius="12px"
                        margin="2% 0"
                        padding="2%"
                        sx={{
                            cursor: "pointer"
                        }}>
                        <FileUpload />

                        <Typography variant="body" size="medium">
                            Upload CSV File
                        </Typography>
                        <Typography variant="body" size="small">
                            Supports .csv files
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            cursor: "pointer"
                        }}
                        justifyContent="space-between"
                        bgcolor="surface-container-low"
                        onClick={handleDownloadClick}
                        border="1px dashed"
                        gap="14px"
                        borderColor="outline-variant"
                        display="flex"
                        borderRadius="12px"
                        margin="2% 0"
                        padding="4.5%">
                        <Box display="flex" gap="14px">
                            <TextSnippet />
                            <Typography variant="body" size="large" mr={15}>
                                {pageState?.csv.file?.name}
                            </Typography>
                        </Box>

                        <HighlightOff onClick={() => handleRemoveFile("csv", pageState?.csv.file?.name)} />
                    </Box>
                )}
                <input
                    ref={csvRef}
                    type="file"
                    accept=".csv"
                    style={{ display: "none" }}
                    onChange={handleCSVUpload}
                />
            </Box>
            <Typography variant="headline" size="small" mt={5}>
                {!pageState?.fastq_files?.length ? (
                    <CircleOutlined sx={{ fontSize: "24px", color: "primary-container", marginRight: "7px" }} />
                ) : (
                    <CheckCircleOutlineOutlined sx={{ fontSize: "24px", color: "primary.main", marginRight: "7px" }} />
                )}
                {/**
                 *
                 *
                 *
                 *
                 *
                 *  FASTQ FILES UPLOADER
                 *
                 *
                 *
                 *
                 *
                 */}
                2. Upload your sample files
            </Typography>
            <Typography variant="body" size="medium" px={4} mt={1}>
                Select all samples files to be included in the experiment. <br />
                *NOTE: All sample files should be submitted in their original format as provided by the sequencing
                organization.
            </Typography>
            <Box
                bgcolor="surface-container-low"
                border="1px dashed"
                borderColor="outline-variant"
                justifyContent="center"
                textAlign="center"
                borderRadius="12px"
                margin="2% 0"
                padding="2%"
                sx={{ cursor: "pointer" }}
                onClick={() => handleSelectFile("fastq")}>
                {pageState?.fastq_files?.map((file: any) => (
                    <Box px={3} display="flex" justifyContent="space-between" my={1} key={file.name}>
                        <Box display="flex" gap="14px">
                            <TextSnippet />
                            <Typography variant="body" size="large">
                                {file.name}
                            </Typography>
                        </Box>
                        <HighlightOff onClick={() => handleRemoveFile("fastq", file.name)} />
                    </Box>
                ))}
                <Box>
                    <FileUpload />

                    <Typography variant="body" size="medium">
                        Upload FASTQ Files
                    </Typography>
                    <Typography variant="body" size="small">
                        Supports .fastq.gz files
                    </Typography>
                    <input
                        ref={fastqRef}
                        type="file"
                        accept=".fastq, .gz, .fq, .fq.gz, .csv"
                        style={{ display: "none" }}
                        onChange={handleFASTQUpload}
                        multiple
                    />
                </Box>

            </Box>
        </Box>
    )
}

export default SampleBulkUpload
