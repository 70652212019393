import React, { useEffect, useState } from "react"
import { Typography, Checkbox, Switch, InputBase, Box } from "@mui/material"
import Collapse from "@mui/material/Collapse"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import {
    ArrowDropDownOutlined,
    ArrowDropUpOutlined,
    CheckCircleOutline,
    Flag,
    FlagOutlined,
    PermIdentityOutlined
} from "@mui/icons-material"
import { styled, alpha } from "@mui/material/styles"
import { Search as SearchIcon } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { ArrowRight } from "@mui/icons-material"
import { Rule } from "@mui/icons-material"
import { Button } from "../../../../components/buttons/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useBff } from "../../../../utils/config"
import PaginationBar from "../../../../components/PaginationBar"
import { useNavigate } from "react-router-dom"
import { useTrovoConfig } from "../../../../utils/config"
import BannerMessage from "../../../AccountDetails/BannerMessage"

export const ActionBar = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20
})

export const ActionItemStyled = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    width: "48%",
    padding: "10px 10px 20px 10px",
    borderRadius: 10,
    border: "1px solid #CAC4D0"
})

export const ActionTitleStyled = styled(Typography)({
    fontSize: 16
})

export const ActionTextStyled = styled(Typography)({
    fontSize: 12,
    color: "#49454F"
})

export const SelectedRowsTextStyled = styled(Typography)({
    fontSize: 14
})

export const FilterTitleStyled = styled(Typography)({
    fontSize: 16,
    fontWeight: 600
})

export const FiltersContainer = styled("div")({
    display: "flex",
    paddingBottom: 10,
    justifyContent: "space-between",
    alignItems: "center"
})

export const FiltersWrapper = styled("div")({
    display: "flex",
    justifyContent: "space-between"
})

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #CAC4D0",
    left: -23,
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto"
    }
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch"
        }
    }
}))

const FilterButtonStyled = styled(Button)({
    borderRadius: "5px",
    "&:hover": {
        outline: "1px solid #6750A4"
    }
})

const MenuStyled = styled(Menu)({
    padding: "0",
    "& .MuiList-root": {
        padding: "0"
    }
})

const MenuItemStyled = styled(MenuItem)({
    display: "flex",
    minWidth: "750px",
    padding: "10px 20px",
    textAlign: "left",
    "&:not(:last-child)": {
        borderBottom: "1px solid #ccc"
    }
})

const createRow = (id: string, sampleName: string, status: string, group: string, selected: boolean, flag: boolean, sex?: string) => {
    return {
        id,
        sampleName,
        status,
        group,
        selected,
        flag,
        sex
    }
}

interface RowProps {
    row: any;
    selected: boolean
    onSelect: () => void
    expId: any
}

const Row = (props: RowProps) => {
    const { row, selected, onSelect, expId } = props
    const [open, setOpen] = React.useState(false)
    const { user } = useTrovoConfig()
console.log(expId)
    return (
        <React.Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "0" }, bgcolor: selected ? "#dbdce273" : "on-primary" }} hover>
                <TableCell sx={{ width: 10 }}>
                    {row?.flag && (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell padding="checkbox" sx={{cursor: expId === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9" ? 'not-allowed' : ''}} >
                    <Checkbox
                        checked={selected}
                        onChange={onSelect}
                        disabled={user.cb_item_price_id?.includes("demo") || expId === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9"}
                    />
                </TableCell>
                <TableCell>{row.flag ? <Flag sx={{ color: "#B3261E" }} /> : ""}</TableCell>
                <TableCell align="left">{row.sampleName || "Sample Name"}</TableCell>
                <TableCell align="left"><CheckCircleOutline color="disabled" sx={{ fontSize:'18px'}} /> {row.status || 'ready'}</TableCell>
                <TableCell align="left">
                    {row?.sex
                        ?.split("_")
                        ?.map((w: string | any[], i: number) => (i == 0 ? w[0].toUpperCase() + w.slice(1) : w))
                        .join(" ")}
                </TableCell>
                <TableCell align="left">{row?.group}</TableCell>
            </TableRow>
        </React.Fragment>
    )
}

const ExpandableTable = (props: {
    formData: any
    setFormData: any
    handleNext: any
    data: any
    error: any
    setError: any
}) => {
    const navigate = useNavigate()
    const { formData, setFormData, handleNext, data, error, setError } = props
    const { samples: selectedSamples } = formData
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const isFilerOpen = Boolean(anchorEl)
    const [perPage, setPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [filtered, setFiltered] = useState(true)
    const { user } = useTrovoConfig()
    const [searchTerm, setSearchTerm] = useState("")
    const rows = filtered
        ? data
              ?.filter((s: { flag: boolean }) => s.flag !== true)
              .map((d: any) =>
                  createRow(
                      d.id,
                      d.name,
                      d.status || d.files.every((f:any) => f.status === 'ready') ? 'ready' : 'error',
                      d.other_categories?.[Object.keys(d.other_categories)?.[0]] || "other",
                      false,
                      d.flag,
                      d.sex || d.organism
                  )
              ) || []
        : data?.map((d: any) =>
              createRow(
                  d.id,
                  d.name,
                  d.status, 
                  d.other_categories?.[Object.keys(d.other_categories)?.[0]] || "other",
                  false,
                  d.flag,
                  d.sex || d.organism
              )
          ) || []
    const startIdx = (page - 1) * perPage
    const endIdx = startIdx + perPage
    const displayedRows = rows.filter((r: any) => r?.sampleName?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    let flagged = data?.filter((s: { flag: boolean }) => s.flag == true)?.length || 0

    const handleSelectRow = (id: string) => {
        setFormData((prev: { name: string; samples: string[] }) => {
            if (prev.samples.includes(id)) {
                return {
                    ...prev,
                    samples: prev.samples.filter((item: string) => item !== id)
                }
            } else {
                return {
                    ...prev,
                    samples: [...prev.samples, id]
                }
            }
        })
    }

    const handleSelectAll = () => {
        if (selectedSamples.length === displayedRows.length) {
            setFormData((prev: any) => ({
                ...prev,
                samples: []
            }))
        } else {
            setFormData((prev: { name: string; samples: string[] }) => {
                return {
                    ...prev,
                    samples: displayedRows.map(({ id }: { id: any }) => id)
                }
            })
        }
    }

    useEffect(() => {
        selectedSamples.length && selectedSamples.length < 6 && !user.cb_item_price_id?.includes("demo")
            ? setError(`Please select at least ${6 - selectedSamples.length} more samples before proceeding.`)
            : setError("")
    }, [selectedSamples])

    useEffect(() => {
        !error && selectedSamples.length > 5 ? handleNext(true) : handleNext(false)
    }, [selectedSamples, error])
    return (
        <>
            <ActionBar sx={{ bgcolor: "surface-container-lowest", padding: "1.5%", borderRadius: "10px" }}>
                <ActionItemStyled>
                    <div>
                        <ActionTitleStyled>Include all flagged samples</ActionTitleStyled>
                        <ActionTextStyled>
                            {flagged} of {data?.length} eligible samples are flagged.
                        </ActionTextStyled>
                    </div>
                    <Switch checked={!filtered} onChange={() => setFiltered(!filtered)} />
                </ActionItemStyled>
                <ActionItemStyled
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate(`/samples/${data?.[0]?.experiment_id}`)}>
                    <div>
                        <ActionTitleStyled>Manage Samples</ActionTitleStyled>
                        <ActionTextStyled>Edit, flag, or review sample statuses.</ActionTextStyled>
                    </div>
                    <ArrowRight sx={{ color: "black" }} />
                </ActionItemStyled>
            </ActionBar>
            <Box sx={{ bgcolor: "surface-container-lowest", padding: "2%", borderRadius: "10px" }}>
                <FiltersContainer>
                    <FiltersWrapper>
                        {/* <FilterButtonStyled
                        aria-controls={isFilerOpen ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={isFilerOpen ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{border:'1px solid', borderColor:'outline'}}
                    >
                       <Rule sx={{ color: "#6750A4", marginRight: 2 }} />
                      <Typography>Filter</Typography> 
                    </FilterButtonStyled> */}
                        <MenuStyled
                            anchorEl={anchorEl}
                            open={isFilerOpen}
                            onClose={() => setAnchorEl(null)}
                            MenuListProps={{ "aria-labelledby": "basic-button" }}>
                            <MenuItemStyled onClick={handleClose}>
                                <Typography>
                                    <b>Filters:</b> Showing {data?.length - flagged} of {data?.length} total samples
                                </Typography>
                            </MenuItemStyled>
                            <MenuItemStyled onClick={handleClose}>
                                <div>
                                    <FilterTitleStyled>Hide ineligible samples</FilterTitleStyled>
                                    <Typography>
                                        Ineligible samples are those that are still in process or require additional
                                        action. These samples are excluded from this <br />
                                        analysis and are hidden from this view by default. To view all samples in this
                                        experiment, you can deselect this option.
                                        <br />
                                        To view the status of ineligible samples, go to Manage Samples.
                                    </Typography>
                                </div>
                                <Checkbox
                                    onChange={() => setFiltered(!filtered)}
                                    disabled={user.cb_item_price_id?.includes("demo")}
                                />
                            </MenuItemStyled>
                        </MenuStyled>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                onChange={e => setSearchTerm(e.target.value)}
                                inputProps={{ "aria-label": "search" }}
                            />
                        </Search>
                    </FiltersWrapper>
                    <SelectedRowsTextStyled>
                        <b>{selectedSamples?.length}</b> of <b>{data?.length}</b> samples selected for analysis
                    </SelectedRowsTextStyled>
                </FiltersContainer>
                <TableContainer sx={{ border: "1px solid", borderColor: "outline", borderRadius: "5px" }}>
                    <Table aria-label="collapsible table">
                        <TableHead sx={{ bgcolor: "surface-container-high" }}>
                            <TableRow>
                                <TableCell width="10px" />
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedSamples.length === rows.length}
                                        onChange={handleSelectAll}
                                        disabled={data?.[0]?.experiment_id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9"}
                                    />
                                </TableCell>
                                <TableCell width="30px" />
                                <TableCell align="left">SAMPLE NAME</TableCell>
                                <TableCell align="left">STATUS</TableCell>
                                <TableCell align="left">{data?.[0]?.organism ? "ORGANISM" : "SEX"}</TableCell>
                                <TableCell align="left">
                                    {data?.[0] && Object.keys(data[0]?.other_categories)?.[0]?.toUpperCase()}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayedRows
                                ?.slice(startIdx, endIdx)
                                .map((row: any) => (
                                    <Row
                                        key={row.id}
                                        row={row}
                                        selected={selectedSamples.includes(row.id)}
                                        onSelect={() => handleSelectRow(row.id)}
                                        expId={data[0].experiment_id} 
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationBar
                    perPage={perPage}
                    setPerPage={setPerPage}
                    page={page}
                    setPage={setPage}
                    totalPages={Math.ceil(displayedRows?.length / perPage)}
                />
            </Box>
        </>
    )
}

export default ExpandableTable
