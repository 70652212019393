import React from "react"
import { Typography, Button } from "@mui/material"
import Checkbox from '@mui/material/Checkbox';
import { styled } from "@mui/material/styles";

export const ContainerStyled = styled("div")({
    padding: "40px"
})

export const TitleStyled = styled(Typography)({
    fontSize: "24px",
    marginBottom: "30px"
})

export const TextStyled = styled(Typography)({
    fontSize: "14px",
    marginBottom: "20px"
})

export const ButtonStyled = styled(Button)({
    width: "100%",
    marginTop: "40px",
    backgroundColor: "black"
})

export const ActionBarStyled = styled("div")({
    display: "flex",
    marginTop: 30,
    alignItems: "center",
    justifyContent: "center"
})

interface BeforeYouBeginProps {
    onClose?: () => void
}

export const BeforeYouBegin: React.FC<BeforeYouBeginProps> = ({ onClose }) => {
    return (
        <ContainerStyled>
            <TitleStyled>
                Before you begin
            </TitleStyled>
            <TextStyled>
                There are a few key aspects of creating an analysis to know before you start. Your samples can only be analyzed <br />
                if they meet the following criteria:<br/>
            </TextStyled>
            <TextStyled>
                - there are at least 6 samples
            </TextStyled>
            <TextStyled>
                - there are at least 2 different groups to compare
            </TextStyled>

            <ButtonStyled onClick={onClose} variant="contained">
                I understand
            </ButtonStyled>
            <ActionBarStyled>
                <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} defaultChecked /> Don’t show this again
            </ActionBarStyled>
        </ContainerStyled>
    );
}
