import React from "react";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { GeneralGraphProps } from "../../types/GraphTypes";
import { InputWithSelect } from "../inputs/InputWithSelect";
import { arrayRange } from "../../utils/helpers";
import {
  Box,
  Divider,
  Grid,
  Input,
  InputLabel,
  InputLabelProps,
} from "@mui/material";
import {
  PosSlider,
  TrovoSliderThumbLeftRight,
  TrovoSliderThumbUpDown,
} from "../inputs/PosSlider";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledInput = styled((props: InputLabelProps) => (
  <InputLabel {...props} />
))(({ theme }) => ({
  ...theme.styles.label.medium,
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: 20,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export function GeneralLayoutController({
  update,
  width,
  height,
  title,
  yTitle,
  xTitle,
  mRight,
  mBottom,
  mTop,
  mLeft,
  fontSizeTitle,
  fontSizeX,
  fontSizeY,
  xTitlePos,
  yTitlePos,
}: GeneralGraphProps & {
  update: Function;
}) {
  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <>
      <Accordion
        expanded={expanded === "sizing"}
        onChange={handleChange("sizing")}
      >
        <AccordionSummary>Plot layout</AccordionSummary>
        <AccordionDetails>
          <div>
            <StyledInput variant="standard">Width</StyledInput>
            <Slider
              aria-label="Plot Width"
              value={width}
              getAriaValueText={() => "Plot Width"}
              step={20}
              marks
              min={200}
              max={1000}
              name="width"
              valueLabelDisplay="auto"
              onChange={(e, value) => {
                update({ name: "width", value: value });
              }}
            />
          </div>
          <div>
            <StyledInput variant="standard">Height</StyledInput>

            <Slider
              aria-label="Plot Height"
              value={height}
              getAriaValueText={() => "Plot Height"}
              step={20}
              marks
              min={200}
              max={1000}
              name="width"
              valueLabelDisplay="auto"
              onChange={(e, value) => {
                update({ name: "height", value: value });
              }}
            />
          </div>
          <Divider style={{ marginBottom: 10 }} />

          <div>
            <InputLabel variant="standard">Margins</InputLabel>

            <Grid
              spacing={3}
              display={"flex"}
              alignContent={"center"}
              container
              direction="row"
            >
              <Grid item sm={6}>
                <StyledInput variant="standard" id={"mTop"}>
                  Top
                </StyledInput>
                <Input
                  id={"mTop"}
                  name={"mTop"}
                  type="number"
                  value={mTop || 0}
                  onChange={(e) =>
                    update({ name: e.target.name, value: e.target.value })
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <StyledInput variant="standard" id={"mBottom"}>
                  Bottom
                </StyledInput>
                <Input
                  id={"mBottom"}
                  name={"mBottom"}
                  type="number"
                  value={mBottom || 0}
                  onChange={(e) =>
                    update({ name: e.target.name, value: e.target.value })
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <StyledInput variant="standard" id={"mLeft"}>
                  Left
                </StyledInput>
                <Input
                  id={"mLeft"}
                  name={"mLeft"}
                  type="number"
                  value={mLeft || 0}
                  onChange={(e) =>
                    update({ name: e.target.name, value: e.target.value })
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <StyledInput variant="standard" id={"mRight"}>
                  Right
                </StyledInput>
                <Input
                  id={"mRight"}
                  name={"mRight"}
                  type="number"
                  value={mRight || 0}
                  onChange={(e) =>
                    update({ name: e.target.name, value: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "titles"}
        onChange={handleChange("titles")}
      >
        <AccordionSummary>Titles</AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <Box display={"flex"}>
              <Stack>
                <PosSlider
                  sx={{
                    height: 60,
                    '& input[type="range"]': {
                      WebkitAppearance: "slider-vertical",
                    },
                  }}
                  thumb={TrovoSliderThumbUpDown}
                  size={"small"}
                  step={0.1}
                  min={0}
                  max={1}
                  name={"yTitlePos"}
                  orientation="vertical"
                  value={yTitlePos}
                  onChange={(e, value) => {
                    update({ name: "yTitlePos", value: value });
                  }}
                />
              </Stack>
              <Box>
                <InputWithSelect
                  inputName="title"
                  title={"Main Title"}
                  inputVal={title}
                  selectLabel={"Size"}
                  selectCustomRender={(v) => `${v}px`}
                  selectName={"fontSizeTitle"}
                  update={({ name, value }) => update({ name, value })}
                  optionVal={fontSizeTitle}
                  options={arrayRange(20, 60, 5)}
                />
                <Stack
                  marginTop={1}
                  direction="row"
                  spacing={2}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <PosSlider
                    size={"small"}
                    step={0.1}
                    min={0}
                    max={1}
                    thumb={TrovoSliderThumbLeftRight}
                    name={"xTitlePos"}
                    value={xTitlePos}
                    onChange={(e, value) => {
                      update({ name: "xTitlePos", value: value });
                    }}
                    sx={{ width: 180 }}
                    orientation={"horizontal"}
                  />
                </Stack>
              </Box>
            </Box>

            <InputWithSelect
              inputName="xTitle"
              title={"X Title"}
              inputVal={xTitle}
              selectLabel={"Size"}
              selectCustomRender={(v) => `${v}px`}
              selectName={"fontSizeX"}
              update={({ name, value }) => update({ name, value })}
              optionVal={fontSizeX}
              options={arrayRange(8, 30, 2)}
            />
            <InputWithSelect
              inputName="yTitle"
              title={"Y Title"}
              selectCustomRender={(v) => `${v}px`}
              inputVal={yTitle}
              selectLabel={"Size"}
              selectName={"fontSizeY"}
              update={({ name, value }) => update({ name, value })}
              optionVal={fontSizeY}
              options={arrayRange(8, 30, 2)}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
