import {
  CSSObject,
  Drawer as MuiDrawer,
  Box,
  BoxProps,
  Theme,
  Tooltip,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  IconButton,
  Paper,
  SvgIcon,
  Container,
  DrawerProps,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import BuildIcon from "@mui/icons-material/Build";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import LanguageIcon from "@mui/icons-material/Language";
import { GlobalController } from "../graphs/GlobalController";
import { Controller, GlobalConfig } from "../../types/GraphTypes";

type DrawerControllers = "globalController" | Controller;

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  height: "100%",
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const PullOut = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})<BoxProps & { open: boolean; drawerWidth: number }>(
  ({ theme, open, drawerWidth }) => ({
    width: drawerWidth,
    ...(open && {
      ...openedMixin(theme, drawerWidth),
      "& .MuiDrawer-paper": openedMixin(theme, drawerWidth),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }),
);

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<DrawerProps & { drawerWidth: number }>(({ theme, open, drawerWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiPaper-root": {
    backgroundColor: theme.schemes.light.inverseOnSurface,
    position: "relative",
  },
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, drawerWidth),
    "& .MuiDrawer-paper": openedMixin(theme, drawerWidth),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SidebySide = styled("div")({
  display: "flex",
});
type TrayItem = {
  icon: any;
  drawer: any;
  toolTip: string;
};
const toolTrays: { [k in Controller]: TrayItem } = {
  dataController: {
    icon: AutoGraphIcon,
    drawer: Drawer,
    toolTip: "Explore the data 👩‍🔬",
  },
  styleController: {
    icon: ModeEditIcon,
    drawer: Drawer,
    toolTip: "Make it beautiful 💅",
  },
  toolsController: {
    icon: BuildIcon,
    drawer: Drawer,
    toolTip: "Extra tools here 👷",
  },
};

const RenderIcon = (comp: typeof SvgIcon, selected: boolean, theme: Theme) => {
  return React.createElement(comp, {
    sx: {
      width: 24,
      height: 24,
      color: theme.schemes.light.onPrimaryContainer,
    },
  });
};

type GraphControlsDrawerProps = {
  open: boolean;
  graphProps: any;
  graph: any;
  drawerWidth: number;
  captureUpdate: (name: string, value: unknown) => void;
  captureUpdateGlobal: (name: string, value: unknown) => void;
  ready: boolean;
  setOpen: Function;
  globals: GlobalConfig;
};
export function GraphControlsDrawer({
  open,
  ready,
  setOpen,
  graph,
  graphProps,
  drawerWidth,
  captureUpdate,
  captureUpdateGlobal,
  globals,
}: GraphControlsDrawerProps) {
  const theme = useTheme();
  const [toolTray, setToolTray] = useState<DrawerControllers>("dataController");
  return (
    <Drawer drawerWidth={drawerWidth} variant="permanent" open={open}>
      <SidebySide>
        <List
          style={{
            height: "100vh",
            background: theme.schemes.light.secondaryContainer,
          }}
        >
          <Tooltip title={"Globals for big changes"} placement={"right"} arrow>
            <ListItem disablePadding style={{ width: 56 }}>
              <ListItemButton
                onClick={() => {
                  if (!open) setOpen(true);
                  setToolTray("globalController");
                }}
                style={{
                  padding: "10px 0",
                  justifyContent: "center",
                }}
              >
                <ListItemIcon
                  style={{
                    borderRadius: 12,
                    border: `1px solid`,
                    borderColor: theme.schemes.light.primary,
                    padding: 5,
                  }}
                  sx={{
                    backgroundColor:
                      toolTray === "globalController"
                        ? "white"
                        : theme.schemes.light.secondaryContainer,
                    minWidth: "unset",
                    justifyContent: "center",
                  }}
                >
                  {RenderIcon(
                    LanguageIcon,
                    toolTray === "globalController",
                    theme,
                  )}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>

          {Object.keys(toolTrays).map((k: string, i: number) => {
            const t = toolTrays[k as Controller];
            return (
              <Tooltip key={i} title={t.toolTip} placement={"right"} arrow>
                <ListItem
                  style={{ width: 56 }}
                  key={t.toolTip}
                  disablePadding
                  sx={{
                    display: "block",
                  }}
                >
                  <ListItemButton
                    style={{
                      justifyContent: "center",

                      padding: "unset",
                    }}
                    onClick={() => {
                      if (!open) setOpen(true);
                      setToolTray(k as Controller);
                    }}
                  >
                    <ListItemIcon
                      style={{
                        ...(i === 0 && {
                          borderTopRightRadius: 12,
                          borderTopLeftRadius: 12,
                        }),
                        ...(i === Object.keys(toolTrays).length - 1 && {
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                        }),

                        padding: 5,
                      }}
                      sx={{
                        border: `1px solid ${theme.schemes.light.primary}`,
                        backgroundColor:
                          toolTray === k
                            ? "white"
                            : theme.schemes.light.secondaryContainer,
                        minWidth: "unset",
                        justifyContent: "center",
                      }}
                    >
                      {RenderIcon(t.icon, toolTray === k, theme)}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            );
          })}
        </List>

        <PullOut drawerWidth={drawerWidth} open={open}>
          {open && (
            <Paper>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <IconButton onClick={() => setOpen(false)}>
                  <ChevronLeft />
                </IconButton>
              </Box>

              {toolTray === "globalController" ? (
                React.createElement(GlobalController, {
                  ...globals,
                  setUpdate: (name, val) => captureUpdateGlobal(name, val),
                })
              ) : (
                <>
                  <Container>
                    {toolTrays[toolTray].toolTip}

                    {React.createElement(graph.controllers[toolTray], {
                      ...graphProps,
                      ready,
                      update: ({ name, value }: { [key: string]: any }) =>
                        captureUpdate(name, value),
                    })}
                  </Container>
                </>
              )}
            </Paper>
          )}
        </PullOut>
      </SidebySide>
    </Drawer>
  );
}
