import React, { useState } from "react";
import Slider from "@mui/material/Slider";

import { FormControlLabel, Checkbox, Stack, Box } from "@mui/material";
import { ChipSelect } from "../../inputs/ChipSelect";
import { updateFunc } from "../../../types";

type VolcanoControlerProps = {
  markSize: number;
  title: string;
  update: updateFunc;
  compare: string[];
  compareList: string[];
  pchoice: string;
  pcut: number;
  FCcut: number;
  max: number;
  fontSize: number;
  yTitle: string;
  ready: boolean;
};

export function VolcanoControls({
  compareList = [],
  compare = [],
  pchoice,
  update,
  FCcut,
  ready,
  max,
}: VolcanoControlerProps) {
  return (
    <Stack alignItems={"center"}>
      <ChipSelect
        title={"Select a Comparison"}
        condition={compare.length === 0 || !compare}
        tip={"Select a compare to continue"}
        name={"compare"}
        currentSelections={compare}
        selectionList={compareList}
        update={update}
      />

      <Box justifyContent={"center"} display={"flex"}>
        <FormControlLabel
          labelPlacement="top"
          control={
            <Checkbox
              checked={pchoice === "padj"}
              name="pchoice"
              value={"padj"}
              onChange={(e) => {
                update({ name: e.target.name, value: e.target.value });
              }}
            />
          }
          label="pvalue"
        />
        <FormControlLabel
          labelPlacement="top"
          control={
            <Checkbox
              checked={pchoice === "pvalue"}
              name="pchoice"
              value={"pvalue"}
              onChange={(e) => {
                update({ name: e.target.name, value: e.target.value });
              }}
            />
          }
          label="padj"
        />
      </Box>
      <label>Y max size</label>
      <Slider
        aria-label="Y Max Size"
        value={max}
        getAriaValueText={() => "Mark Size"}
        step={5}
        marks
        min={10}
        max={100}
        name="max"
        valueLabelDisplay="auto"
        onChange={(e, value) => {
          update({ name: "max", value: value });
        }}
      />
      <label>FCcut size</label>
      <Slider
        aria-label="Y Max Size"
        value={FCcut}
        getAriaValueText={() => "Mark Size"}
        step={0.5}
        marks
        min={1}
        max={10}
        name="FCcut"
        valueLabelDisplay="auto"
        onChange={(e, value) => {
          update({ name: "FCcut", value: value });
        }}
      />
    </Stack>
  );
}
