import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";

import React from "react";
import { GeneralLayoutController } from "../GeneralLayoutController";
import { GeneralGraphProps } from "../../../types/GraphTypes";
import { ColorPicker } from "../../special/ColorPicker";
import { VolcanoColorMap, VolcanoMark } from "./index";

type VolcanoControlerProps = {
  markSize: number;
  title: string;
  update: Function;
  fontSize: number;
  yTitle: string;
  width: number;
  height: number;
  colorMap: VolcanoColorMap;
};
export function VolcanoControlsLayout(
  props: VolcanoControlerProps & GeneralGraphProps,
) {
  const { update, markSize, colorMap } = props;
  return (
    <Stack>
      <div>
        <div>Mark size</div>
        <Slider
          aria-label="Mark Size"
          value={markSize}
          getAriaValueText={() => "Mark Size"}
          step={1}
          marks
          min={5}
          max={10}
          name="markSize"
          valueLabelDisplay="auto"
          onChange={(e, value) => {
            update({ name: "markSize", value: value });
          }}
        />
      </div>
      {colorMap &&
        Object.keys(colorMap).map((k) => {
          const v = colorMap[k as VolcanoMark];
          return (
            <ColorPicker
              color={v}
              label={k}
              update={(p: { name: string; value: string }) => {
                const copy = { ...colorMap };
                copy[k as VolcanoMark] = p.value;
                update({ name: "colorMap", value: copy });
              }}
            />
          );
        })}
      <GeneralLayoutController {...props} update={update} />
    </Stack>
  );
}
