import React from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"
import { useAuth0 } from "@auth0/auth0-react"
import { getAppConfig } from "../../utils/config"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "602px",
    display: "flex",
    flexDirection: "column",
    gap: "43px",
    height: 705,
    bgcolor: "#FEF7FF",
    borderRadius: "12px",
    boxShadow: 24,
    padding: "93px 58px"
}

interface ModalDemoPlanProps {
    open: boolean
    setClose: () => void
    plan?: Plan
}

const ModalDemoPlan: React.FC<ModalDemoPlanProps> = ({ open, setClose, plan }) => {
    const { getAccessTokenSilently } = useAuth0()
    const { apiHost } = getAppConfig()

    const handleSubmit = async () => {
        try {
            const bodyObject = {
                item_price_id: plan?.id,
                quantity: 1
            }
            console.log("bodyObject: ", bodyObject)
            const response = await fetch(`${apiHost}/subscriptions/first-time-subscribe`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(bodyObject)
            })
            const responseData = await response.json()
            if (response.ok) {
                console.log("OK response from handleSubmit")
                console.log("responseData: ", responseData)
                if (responseData.message === "Successfully subscribed") {
                    window.location.href = `/?welcome=true`
                }
            } else {
                console.log("Error response for handleSubmit")
                console.log("responseData: ", responseData)
                return "Error"
            }
        } catch (err) {
            console.log("error in invokePaymentProcess: ", err)
            return "Error"
        }
    }

    return (
        <Modal open={open}>
            <Box sx={style}>
                <CloseIcon
                    sx={{
                        position: "absolute",
                        width: "30px",
                        height: "30px",
                        top: "18px",
                        right: "27px",
                        "&:hover": { cursor: "pointer" }
                    }}
                    onClick={setClose}
                />
                <Typography variant="headline" size="large">
                    Some details regarding access to our Demo Environment
                </Typography>
                <Box display="flex" flexDirection="column" gap="20px">
                    <Typography variant="title" size="large">
                        What you can do
                    </Typography>
                    <Typography variant="body" size="medium" paddingBottom="5px">
                        The Trovomics Demo Environment is intended for researchers to have the ability to explore the
                        platform with no time limit or data restrictions. It provides a way to work with the platform’s
                        functionality without commitment. Access to the Demo Environment allows you to: manage your
                        account, view the Main Dashboard, work with analyzed data to generate a variety of
                        visualizations, and generally get to know your way around the platform.
                    </Typography>
                    <Typography variant="title" size="large">
                        What you cannot do
                    </Typography>
                    <Typography variant="body" size="medium" paddingBottom="5px">
                        The Demo Environment is populated with RNA-Seq data that has already gone through the experiment
                        creation and analysis process. You cannot upload your own data into the Demo Environment.
                    </Typography>
                </Box>
                <Button onClick={handleSubmit} variant="contained" fullWidth>
                    Got it. Let's go!
                </Button>
            </Box>
        </Modal>
    )
}

export default ModalDemoPlan
