import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";

import React from "react";
import { GeneralLayoutController } from "../GeneralLayoutController";
import { GeneralGraphProps } from "../../../types/GraphTypes";
import { PCAProps } from "./index";
import { updateFunc } from "../../../types";
type PCAUpdateProps = PCAProps & {
  update: updateFunc;
};
export function PCAControlsLayout(props: PCAUpdateProps & GeneralGraphProps) {
  const { update, markSize } = props;
  return (
    <Stack>
      <div>
        <div>Mark size</div>
        <Slider
          aria-label="Mark Size"
          value={markSize}
          getAriaValueText={() => "Mark Size"}
          step={1}
          marks
          min={5}
          max={10}
          name="markSize"
          valueLabelDisplay="auto"
          onChange={(e, value) => {
            update({ name: "markSize", value: value });
          }}
        />
      </div>

      <GeneralLayoutController {...props} update={update} />
    </Stack>
  );
}
