import {
  Box,
  Chip,
  MenuItem,
  Stack,
  Button,
  Grid,
  Menu,
  useTheme,
  Typography,
  Tooltip,
  Theme,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";

import React from "react";
import { updateFunc } from "../../types";
import { HowToTip } from "../special/HowToTip";

type ChipSelectProps = {
  name: string;
  title: string;

  currentSelections: any[];
  selectionList: any[];
  update: updateFunc;
  condition: boolean;
  tip: string;
};

type HowToButtonProps = {
  title: string;
  theme: Theme;
  name: string;
  open: boolean;
  condition: boolean;
  disabled?: boolean;
  tip: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

function HowToButton({
  title,
  theme,
  name,
  onClick,
  open,
  condition,
  disabled,
  tip,
}: HowToButtonProps) {
  return (
    <HowToTip title={tip} condition={condition}>
      <Button
        disabled={disabled}
        style={{
          border: `1px solid ${theme.schemes.light.onSurfaceVariant}`,
          margin: theme.spacing(1),
          color: theme.schemes.light.onSurfaceVariant,
        }}
        sx={{
          "&.Mui-disabled": {
            color: theme.palette.primary.main,
            opacity: 0.5,
          },
        }}
        variant={"outlined"}
        id={`open-${name}-list`}
        aria-controls={open ? `${name}-list` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={onClick}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            style={{
              ...theme.styles.label.large,
            }}
          >
            {title}
          </Typography>

          <ArrowDropDownIcon
            style={{
              transition: theme.transitions.create("transform", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
              ...(open && {
                transform: "rotate(-180deg)",
              }),
            }}
          />
        </Box>
      </Button>
    </HowToTip>
  );
}

export function ChipSelect({
  currentSelections,
  selectionList,
  condition,
  update,
  title,
  name,
  tip,
}: ChipSelectProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function updateSelect(value: string) {
    update({ name, value: [...currentSelections, value] });
    handleClose();
  }

  return (
    <Stack display={"flex"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={1}>
          {currentSelections &&
            currentSelections.map((value: string) => (
              <Grid item>
                <Tooltip arrow placement={"top-end"} title={value}>
                  <Chip
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "currentcolor",
                      },
                    }}
                    deleteIcon={<CloseIcon />}
                    size={"small"}
                    style={{
                      backgroundColor: theme.schemes.light.secondaryContainer,
                      borderRadius: 8,
                      maxWidth: 100,
                    }}
                    onDelete={(e) => {
                      update({
                        name,
                        value: currentSelections.filter((c) => c !== value),
                      });
                    }}
                    key={value}
                    label={value}
                  />
                </Tooltip>
              </Grid>
            ))}
        </Grid>
      </Box>
      <HowToButton
        disabled={
          selectionList &&
          selectionList.filter((s) => !currentSelections.includes(s)).length ===
            0
        }
        title={title}
        name={name}
        open={open}
        condition={condition}
        tip={tip}
        theme={theme}
        onClick={handleClick}
      />

      <Menu
        id={`${name}-list-menu`}
        aria-labelledby={`open-${name}-list`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "left", vertical: 0 }}
        anchorOrigin={{ horizontal: "left", vertical: 40 }}
      >
        {selectionList &&
          selectionList
            .filter((s) => !currentSelections.includes(s))
            .map((o: string) => (
              <MenuItem key={o} value={o} onClick={() => updateSelect(o)}>
                {o}
              </MenuItem>
            ))}
      </Menu>
    </Stack>
  );
}
