import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "../../../../components/buttons/Button";

const CancelModalContainerStyled = styled("div")({
    display: "block",
    width: "100%",
    padding: "40px"
});

const ButtonStyled = styled(Button)({
    display: "block",
    width: "100%",
    marginBottom: "20px"
});

const CancelButtonStyled = styled(ButtonStyled)({
    outline: "1px solid #6750A4",
    backgroundColor: "white",
    color: "#6750A4"
});

export const CancelAnalysisModal = () => {
    return (
        <CancelModalContainerStyled>
            <Typography style={{ marginBottom: '20px', fontSize: '28px' }}>
                Are you sure you want to cancel this analysis?
            </Typography>
            <Typography style={{ marginBottom: '20px', fontSize: '14px' }}>
                Cancelling this analysis will cause the system to stop processing your data.<br/>
                The sample data associated with this analysis is unchanged and remains available within your experiment.
            </Typography>
            <Typography style={{ marginBottom: '80px', fontSize: '14px' }}>
                Do you still want to cancel?
            </Typography>

            <ButtonStyled variant="contained" style={{ backgroundColor: '#6750A4' }} >
                Yes, cancel this analysis
            </ButtonStyled>
            <CancelButtonStyled variant="contained">
                No, let this analysis continue processing
            </CancelButtonStyled>
        </CancelModalContainerStyled>
    );
}
