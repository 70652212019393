import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { TrovoUser, Config } from "../types";

const getWindowProperty = <T>(property: any, defaultValue: T): T => {
  if (typeof window !== "undefined" && window[property]) {
    return window[property] as T;
  }
  return defaultValue;
};

// Environment-specific config not needed since env vars updated in docker according to environment
// if no env vars are present, the application will by default use the development Auth0 tenant
const auth0Config = {
  auth0Domain: getWindowProperty<string>(
    "AUTH0_TENANT",
    "dev-eoxtei4d.us.auth0.com",
  ),
  auth0ClientId: getWindowProperty<string>(
    "AUTH0_CLIENT_ID",
    "xp69HW4bQFnKfeMBJaWqVF0cMTya4Ufi",
  ),
  auth0Audience: getWindowProperty<string>(
    "AUTH0_AUDIENCE",
    "https://api.trovomics.com",
  ),
  // other properties...
};

const appConfig: Config = {
  // Do not hardcode api values. The UI needs to behave appropriately based on the environment
  // If you wish to use a different api host, you can set the OR ("||") operator to the desired value
  // Local development will not have an environment variables set, so the default value will be used
  apiHost: (window as any).BFF_URL || "https://api.dev.trovomics.com",
  apiVersion: "",
  buildRoute: function (path: string) {
    return `${this.apiHost}/${this.apiVersion}${path}`;
  },
  ...auth0Config,
};

export const getAppConfig = () => {
  return appConfig;
};

export function setAuthHeader(token: string) {
  return {
    Authorization: `Bearer ${token}`,
  };
}
export function useTrovoConfig() {
  const [refetch, setRefetch] = useState(false);
  const [trovoUser, setTrovoUser] = useState({} as TrovoUser);
  const auth0 = useAuth0();
  const navigate = useNavigate();
  const { getAccessTokenSilently, user, isAuthenticated, isLoading } = auth0;
  const apiConfig = getAppConfig();
  const location = useLocation();
  const currentPage = location.pathname;
  // const getTrovoUser = useCallback(
  //   async function () {
  //     const url = apiConfig.buildRoute(`user/${user?.sub}`);
  //     const token = await getAccessTokenSilently();
  //     const res = await fetch(url, {
  //       headers: { ...setAuthHeader(token) },
  //     });
  //     const data = await res.json();
  //     return { ...user, ...data };
  //   },
  //   [apiConfig, user, getAccessTokenSilently],
  // );

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate("/login");
      return;
    }
    if (
      isAuthenticated &&
      !isLoading &&
      user &&
      user.email_verified === false
    ) {
      navigate("/verify");
      return;
    }
    if (
      isAuthenticated &&
      !isLoading &&
      user &&
      !user.subscription_id &&
      user.cb_subscription_status !== "active" &&
      user.cb_subscription_status !== "non_renewing"
    ) {
      if (
        currentPage !== "/verified" &&
        currentPage !== "/plans" &&
        currentPage !== "/pay"
      ) {
        navigate("/verified");
      }
      return;
    }
    // getTrovoUser()
    //     .then(data => {
    //         setTrovoUser({ ...user, ...data })
    //         if (refetch) {
    //             setRefetch(false)
    //         }
    //     })
    //     .catch(error => console.log(error))
  }, [/*getTrovoUser, */ isAuthenticated, isLoading, navigate, user, refetch]);
  return {
    ...auth0,
    // user: { ...user, ...trovoUser },
    user: { ...user },
    refreshUser: () => setRefetch(true),
  };
}
type reqError = {
  status?: number;
  error?: string;
};

export function useBff(method: string, path: string, body?: BodyInit) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null as any);
  const [error, setError] = useState({
    status: 0,
    error: undefined,
  } as reqError);
  const { getAccessTokenSilently } = useAuth0();

  const apiConfig = getAppConfig();
  const getData = useCallback(
    async function () {
      try {
        const token = await getAccessTokenSilently();
        const opt: RequestInit = {
          method,
          headers: {
            ...setAuthHeader(token),
            "Content-Type": "application/json",
          },
        };
        if (body) {
          opt.body = body;
        }
        const res = await fetch(apiConfig.buildRoute(path), opt);
        if (res.status !== 200) {
          setError({
            status: res.status,
            error: (await res.text()) || "",
          });
          return;
        }
        const data = await res.json();

        setData(data);
      } catch (e: any) {
        setError({
          error: e.toString(),
        });
      }
    },
    [body, apiConfig, path, method, getAccessTokenSilently],
  );

  useEffect(() => {
    setIsLoading(true);
    getData().then(() => setIsLoading(false));
  }, [getData]);

  return [data, error, isLoading];
}
