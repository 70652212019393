import * as Plotly from "plotly.js";
import React, { useEffect, createRef, useState } from "react";
import { Snackbar, Alert as MuiAlert, AlertProps } from "@mui/material";
import { PlotlyHTMLElement } from "plotly.js";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);
type CustomPlotProps = {
  setFigure: Function;
  setSelections: Function;

  style?: React.CSSProperties;
};
type PlotRef = PlotlyHTMLElement & HTMLDivElement;
const baseconfig: Partial<Plotly.Config> = {
  displayModeBar: false,
  showTips: false,
};
export function CustomPlot({
  style,
  setFigure,
  setSelections,
}: CustomPlotProps) {
  const plot = createRef<PlotRef>();
  const [snack, setSnack] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack(false);
  };

  useEffect(() => {
    if (!plot.current?.data) {
      Plotly.newPlot(plot.current!, [], {}, baseconfig).then((res) =>
        console.log(res, "new plot set"),
      );
      plot.current!.on("plotly_selected", (e) => {
        if (!e || e.points.length === 0) return;
        setSnack(true);
        // @ts-ignore
        setSelections(e.points.map((p, i) => ({ id: i, ...p.customdata })));
      });
      plot.current!.on("plotly_deselect", () => {
        setSnack(false);
        setSelections([]);
      });
      plot.current!.on("plotly_click", (e) => {
        console.log(e, "CLICKED BB");
      });
      plot.current!.on("plotly_relayout", (e) => {
        console.log(e, "RELAYOUT BB");
      });
      //set after all the events are attached
      setFigure(plot.current);
    }
  }, [plot, setFigure, setSelections]);
  return (
    <div style={style}>
      <div id="CustomGraph" ref={plot}></div>
      <Snackbar open={snack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Double click to deselect :)
        </Alert>
      </Snackbar>
    </div>
  );
}
