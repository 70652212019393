import React, { useEffect } from "react"
import {
    Typography,
    Box,
    Grid,
    Paper,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    styled,
    Button
} from "@mui/material"
import { Edit, ModeEdit, KeyboardArrowDown, Expand } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"

interface Step5Props {
    experiment: any;
    formData: any
    samples: any;
    setStep: (step: number) => void
    submitExperiment: () => void
}
const TextRow = styled(Box)({
    display: "flex"
})

const Step5: React.FC<Step5Props> = ({ experiment, formData, samples, setStep, submitExperiment }) => {
    const navigate = useNavigate()
   

    const handleEdit = (step: number) => {
        setStep(step)
    }
 
    return (
        <Box>
            <Accordion defaultExpanded sx={{ bgcolor: "on-primary", boxShadow: "none" }}>
                <AccordionSummary
                    expandIcon={<KeyboardArrowDown />}
                    aria-controls="experiment-details-content"
                    id="experiment-details-header"
                    sx={{
                        flexDirection: "row-reverse",
                        width: "100%",
                        bgcolor: "surface-container-high",
                        borderRadius: "12px 12px 0 0"
                    }}>
                    <Box ml={2} my={0} py={0} sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Typography variant="title" size="medium">
                            Experiment details
                        </Typography>
                        <span
                            style={{ display: "flex" }}
                            onClick={() => {
                                handleEdit(0)
                            }}>
                            <ModeEdit color="primary" sx={{ fontSize: "18px", margin: "0 5px" }} />
                            <Typography variant="label" size="large" color={"primary.main"}>
                                Edit
                            </Typography>
                        </span>
                    </Box>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        border: "1px solid",
                        borderColor: "surface-container-highest",
                        borderRadius: "0 0 8px 8px",
                        borderTop: "none",
                        width: "100%"
                    }}>
                    <TextRow>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                width: "90%",
                                gap: 1
                            }}>
                            <Typography variant="title" size="medium">
                                Name:
                            </Typography>
                            <Typography variant="title" size="medium">
                                Description:
                            </Typography>
                            <Typography variant="title" size="medium">
                                Organism:
                            </Typography>
                            <Typography variant="title" size="medium">
                                Sequencing type:
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "90%", gap: 1 }}>
                            <Typography variant="body" size="large">
                                {experiment?.name}
                            </Typography>
                            <Typography> {experiment?.description || "N/A"}</Typography>
                            <Typography>{experiment?.organism}</Typography>
                            <Typography> {experiment?.sequencing_type}</Typography>
                        </Box>
                    </TextRow>
                </AccordionDetails>
            </Accordion>

            {/* Sequencing Details */}
            <Accordion defaultExpanded sx={{ bgcolor: "on-primary", boxShadow: "none" }}>
                <AccordionSummary
                    expandIcon={<KeyboardArrowDown />}
                    aria-controls="experiment-details-content"
                    id="experiment-details-header"
                    sx={{
                        flexDirection: "row-reverse",
                        width: "100%",
                        bgcolor: "surface-container-high",
                        borderRadius: "12px 12px 0 0"
                    }}>
                    <Box ml={2} my={0} py={0} sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Typography variant="title" size="medium">
                            Sequencing details
                        </Typography>
                        <span
                            style={{ display: "flex" }}
                            onClick={() => {
                                handleEdit(1)
                            }}>
                            <ModeEdit color="primary" sx={{ fontSize: "18px", margin: "0 5px" }} />
                            <Typography variant="label" size="large" color={"primary.main"}>
                                Edit
                            </Typography>
                        </span>
                    </Box>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        border: "1px solid",
                        borderColor: "surface-container-highest",
                        borderRadius: "0 0 8px 8px",
                        borderTop: "none",
                        width: "100%"
                    }}>
                    <TextRow>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "90%", gap: 1 }}>
                            {Object.keys(formData.sequencing_details).map((k, i) => {
                                return (
                                    <Typography key={i} variant="title" size="medium">
                                        {k
                                            .split("_")
                                            .map(word => word[0].toUpperCase() + word.slice(1))
                                            .join(" ")}
                                    </Typography>
                                )
                            })}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "90%", gap: 1 }}>
                            {Object.keys(formData.sequencing_details).map((k: any, i) => {
                                return (
                                    <Typography key={i} variant="body" size="large">
                                        {k === 'sequencing_sense' && formData.sequencing_details[k] == '' ? 'unstranded' : formData.sequencing_details[k]}
                                    </Typography>
                                )
                            })}
                        </Box>
                    </TextRow>
                </AccordionDetails>
            </Accordion>

            {/* Samples */}
            <Accordion defaultExpanded sx={{ bgcolor: "on-primary", boxShadow: "none" }}>
                <AccordionSummary
                    expandIcon={<KeyboardArrowDown />}
                    aria-controls="experiment-details-content"
                    id="experiment-details-header"
                    sx={{
                        flexDirection: "row-reverse",
                        width: "100%",
                        bgcolor: "surface-container-high",
                        borderRadius: "12px 12px 0 0"
                    }}>
                    <Box ml={2} my={0} py={0} sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Typography variant="title" size="medium">
                            Sample details
                        </Typography>
                        <span
                            style={{ display: "flex" }}
                            onClick={() => {
                                handleEdit(3)
                            }}>
                            <ModeEdit color="primary" sx={{ fontSize: "18px", margin: "0 5px" }} />
                            <Typography variant="label" size="large" color={"primary.main"}>
                                Edit
                            </Typography>
                        </span>
                    </Box>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        border: "1px solid",
                        borderColor: "surface-container-highest",
                        borderRadius: "0 0 8px 8px",
                        borderTop: "none",
                        width: "100%"
                    }}>
                    <TextRow>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "90%", gap: 1 }}>
                            <Typography variant="title" size="medium">
                                Number of Samples
                            </Typography>
                            <Typography variant="title" size="medium">
                                Files
                            </Typography>
                            <Typography variant="title" size="medium">
                                Variables
                            </Typography>
                            <Typography variant="title" size="medium">
                                Values
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "90%", gap: 1 }}>
                            <Typography>
                                {experiment?.sample_count}
                            </Typography>
                            <Typography> {samples?.reduce((total: number, sample: any) => {
                                    return total + (sample.files ? sample.files.length : 0);
                                }, 0)}</Typography>

                            <Typography>{Object.keys(formData.step_three_details)?.join("; ")}</Typography>
                            <Typography>{Object.values(formData.step_three_details).flat().join("; ")}</Typography>
                        </Box>
                    </TextRow>
                </AccordionDetails>
            </Accordion>
            <Button variant="contained" color="primary" onClick={submitExperiment} fullWidth>
                Save and Continue
            </Button>
            <Button variant="text" color="primary" fullWidth>
                Save Progress and Exit
            </Button>
        </Box>
    )
}
export default Step5
