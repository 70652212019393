import React from "react"
import { Box, Typography } from "@mui/material"
import { ErrorRounded } from "@mui/icons-material"

interface ToDemoWarningProps {}

const ToDemoWarning: React.FC<ToDemoWarningProps> = () => {
    return (
        <Box bgcolor="#FFD8E4" padding="24px" borderRadius="12px" display="flex" flexDirection="row" gap="14px">
            <Box display="flex" alignItems="center">
                <ErrorRounded />
            </Box>
            <Typography variant="title" size="medium">
                Warning: Changing your plan to the Demo Environment will remove access to all created experiments
                and analyses associated with your account. Only the demo data set(s) will be available.
            </Typography>
        </Box>
    )
}

export default ToDemoWarning
