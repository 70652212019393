import React, { useState } from "react"
import { Route } from "react-router-dom"
import PlanSelection from "../PlanSelection"
import Pay from "../Pay"

interface PlansAndPayProps {}

const PlansAndPay: React.FC<PlansAndPayProps> = () => {
    const [plan, setPlan] = useState<Plan | null>(null)

    return <>{!plan ? <PlanSelection setPlan={setPlan} /> : <Pay plan={plan} unselectPlan={() => setPlan(null)} />}</>
}

export default PlansAndPay
